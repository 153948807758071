export default {
    title: {
        list: 'Lịch sử hoạt động',
        detail: 'Chi tiết hoạt động'
    },
    type: {
        default: 'Tải xuống/Tải lên ca chụp',
        login: 'Đăng nhập',
        send_study: 'Gửi ca',
        approve_report: 'Duyệt ca',
        reject_report: 'Hủy kết quả duyệt',
        overwrite_report: 'Lưu kết quả sau khi báo lỗi',
        change_report: 'Thay đổi kết quả',
        complete_report: 'Lưu kết quả'
    }
};
