export default {
    button: {
        Zoom: 'Zoom Image',
        'Zoom In': 'Zoom In',
        'Zoom Out': 'Zoom Out',
        'Zoom to fit': 'Zoom to fit',
        Magnify: 'Magnify',
        Pan: 'Pan Image',
        'Stack Scroll': 'Scroll Image',
        Levels: 'Levels',
        'Invert B/W': 'Inverse Image',
        'Flip/Rotation': 'Flip/Rotation',
        'Flip Horizontal': 'Flip Horizontal',
        'Flip Vertical': 'Flip Vertical',
        Rotate: 'Rotate',
        Rotate90L: 'Counterclockwise rotate 90°',
        Rotate90R: 'Clockwise rotate 90°',
        Reset: 'Reset Image',
        Synchronize: 'Synchronize',
        SynchronizeSlice: 'Scroll by Position',
        SynchronizeSliceOffset: 'Scroll offset',
        SynchronizeZoom: 'Zoom',
        SynchronizeWindow: 'Window',
        Slice: 'Slice',
        'WW/WL': 'Adjust Window',
        Annotate: 'Text',
        Measurements: 'Measurements',
        MeasurementsList: 'Lịch sử đo đạc trên ca',
        MyMeasurementsList: 'Đo đạc của tôi',
        SaveMyMeasurements: 'Lưu vào đo đạc của tôi',
        Length: 'Length',
        Angle: 'Angle',
        CobbAngle: 'Cobb Angle',
        Bidirectional: 'RECIST',
        Ellipse: 'Ellipse',
        CircleRoi: 'Circle',
        Rectangle: 'Rectangle',
        'Freehand ROI': 'Freehand',
        'ROI Window': 'ROI Window',
        Brush: 'Brush',
        Probe: 'Pixel',
        Eraser: 'Eraser',
        Reports: 'Chẩn đoán',
        StudyInfo: 'Thông tin ca chụp',
        Series: 'Sidebar',
        Layout: 'Series Layout',
        CINE: 'CINE',
        Ruler: 'Ruler',
        Clear: 'Erase all',
        View: 'View',
        MPR: 'MPR',
        '2D MPR': 'MPR normal',
        'PAPAYA MPR': 'MPR with GPU supported',
        Switch: 'Switch',
        Oblique: 'Oblique',
        Orthogonal: 'Orthogonal',
        'Draw Oblique Plane': 'Draw Oblique Plane',
        Window: 'WW/WL',
        Crosshair: '3D Cursor',
        'CT Abdomen': 'CT Abdomen (W: 350, L:50)',
        'CT Bone': 'CT Bone (W: 2500, L:500)',
        'CT Cerebrum': 'CT Cerebrum (W: 120, L:40)',
        'CT Liver': 'CT Liver (W: 150, L:50)',
        'CT Lung': 'CT Lung (W: 1500, L:-500)',
        'CT Mediastinum': 'CT Mediastinum (W: 300, L:50)',
        'CT Pelvis': 'CT Pelvis (W: 400, L:40)',
        'CT Posterior Fossa': 'CT Posterior Fossa (W: 250, L:80)',
        'References Lines': 'References Lines',
        Cornerstone: '2D',
        SaveAs: 'Save ảnh hiện tại',
        CopyImage: 'Copy ảnh hiện tại',
        Fullscreen: 'Fullscreen',
        '3DITK': '3D',
        Crop: 'Crop',
        IntensityProjection: 'MIP/MinIP/AvgIP',
        Anonymize: 'Anonymize patient data',
        InputWindow: 'Input window',
        SlabThickness: 'Slab thickness',
        Intensity: 'MIP',
        blendModes: 'Projection mode',
        ColorMap: 'Color Map',
        Relabel: 'Nhãn',
        Description: 'Miêu tả',
        Delete: 'Xóa',
        SaveMeasurement: 'Lưu ghi chú',
        CMPR: 'C-MPR',
        'CMPR.Active': 'Modify curve',
        'CMPR.Inactive': 'Stop modifying curve',
        'CMPR.Clear': 'Delete curve',
        viewAnnotation: 'Xem ghi chú',
        BookmarkStudy: 'Lưu vào thư mục cá nhân',
        DeleteBookmark: 'Bỏ lưu khỏi thư mục cá nhân',
        Download: 'Download',
        AnnotationToggle: 'Toggle Annotations',
        ImageQuality: 'Chất lượng ảnh',
        'ImageQuality.Best': 'Tốt',
        'ImageQuality.Good': 'Khá',
        'ImageQuality.Medium': 'Trung bình',
        StudyShare: 'Study share',
        GetLossLessImage: 'Hiện ảnh chất lượng cao',
        StudiesHistory: 'Lịch sử chụp',
        AddAttachment: 'Đính kèm ảnh vào kết quả',
        ViewerSync: 'LiveShare',
        CopyID: 'Copy ID',
        retry: 'Thử lại'
    },
    blendModes: {
        MIP: 'MIP',
        MinIP: 'MinIP',
        AvgIP: 'AvgIP'
    },
    message: {
        error: 'Không thể xem ảnh chụp do kết nối mạng không ổn định. Hãy thử lại hoặc liên hệ quản trị viên',
        Downloading: 'Đang tải series',
        MPRBuild: 'Đang tái tạo MPR',
        volumeBuilding: 'Đang tái tạo 3D',
        SliceSpacingWarning: 'Khoảng cách giữa các lát cắt không ổn định, hình ảnh tái tạo có thể không thể hiện đúng tỉ lệ giải phẫu!',
        SwitchSeriesNotReconstructable: 'Series được chọn không đúng cấu trúc để có thể tái tạo!',
        saveAnnotationSuccess: 'Đo đạc của bác sĩ <b>%{fullName}</b> đã được lưu.',
        copySuccess: 'Ảnh hiện tại đã được lưu vào bộ nhớ.',
        copyError: 'Không thể lưu ảnh vào bộ nhớ do trình duyệt không hỗ trợ.',
        isHosting: 'đang trình chiếu cho %{numClients} người dùng.',
        isReceiving: 'đang xem viewer của %{hostID}.'
    },
    windowWidth: 'WW',
    windowCenter: 'WC',
    Measurements: 'Đo đạc',
    EditDescription: 'Sửa miêu tả',
    AssignLabel: 'Gán nhãn',
    saveSuccess: 'Đo đạc đã được cập nhật thành công!',
    emptyViewport: 'Kéo thả series để xem ảnh',
    images: 'ảnh',
    status: 'Trạng thái',
    lastUpdated: 'Cập nhật gần nhất',
    annotation: {
        list: 'Các đo đạc đã lưu trữ',
        saveTitle: 'Lưu thông tin đo đạc',
        download: 'Tải',
        createdTime: 'Ngày tạo',
        fullName: 'Người tạo',
        addGroup: 'Thêm mới',
        save: 'Lưu',
        saveAs: 'Tạo mới',
        StudyDate: 'Ngày chụp'
    },
    viewerSharing: {
        title: 'Trình chiếu viewer',
        hosting: 'Trình chiếu viewer cho người dùng khác',
        hostingGuide: 'Hướng dẫn: copy mã ID và gửi cho người dùng muốn xem viewer,  hệ thống sẽ tự thông báo khi có người dùng truy cập vào để xem viewer hiện tại.',
        viewing: 'Xem viewer của người dùng khác',
        viewingGuide: 'Hướng dẫn: mở cửa sổ xem trình chiếu và nhập mã ID của người chiếu gửi cho bạn, hệ thống sẽ tự đồng bộ với viewer của người chiếu.',
        presenter: 'Người trình bày',
        openViewingWindow: 'Mở cửa sổ xem trình chiếu',
        enterHostID: 'Nhập mã ID của người trình chiếu',
        destroy: 'Ngắt kết nối',
        minimize: 'Thu nhỏ'
    }
};
