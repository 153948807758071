export default {
    title: {
        procedureList: 'Dịch vụ chụp',
        procedureCreate: 'Thêm dịch vụ chụp',
        procedureEdit: 'Sửa dịch vụ chụp'
    },
    notification: {
        addTemplate: {
            success: 'Cập nhật thành công mẫu nội dung cho dịch vụ',
            fail: 'Không thể cập nhật mẫu nội dung cho dịch vụ. Vui lòng thử lại hoặc liên hệ quản trị viên'
        },
        addPdfTemplate: {
            success: 'Cập nhật mẫu hiển thị thành công cho dịch vụ',
            fail: 'Không thê cập nhật mẫu hiển thị cho dịch vụ. Vui lòng thử lại hoặc liên hệ quản trị viên'
        },
        delete: {
            confirm: 'Bạn có chắc chắn xóa dịch vụ chụp <b>%{name}</b> không?'
        }
    }
};
