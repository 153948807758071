import { STUDY } from '../configurations/resources/resources';

export const generateTabKey = (tabData) => {
    const { resource, data } = tabData;
    return `${resource}-${data.id}`;
};

export const generateTab = (fullTabData, basePath) => {
    const { subTabs, ...tabData } = fullTabData;
    const { resource, data = {}, url } = tabData;
    console.log('tab data', tabData);
    let key = `${resource}-${data.id}`;
    let { titlePrefix } = data;
    if (titlePrefix === undefined || titlePrefix === null) titlePrefix = `resources.${resource}.name`;
    // let title = `${translate(resource === 'viewer' || resource === STUDIES ? `title.${resource}` : `resources.${resource}.name`)}`;
    let title;
    const tabUrl = url || `${basePath}${resource}/${data.id}`;
    switch (resource) {
        case STUDY:
        case 'viewer':
            titlePrefix = `title.${resource}`;
            title = (data.order.patient && data.order.patient.fullName) || data.studyInstanceUID;
            break;
        case 'viewer-compare':
            titlePrefix = 'page.study.button.compare';
            key = `${resource}-${data.map((study) => study.id).join(',')}`;
            title = data.map((item) => item.order && item.order.patient && item.order.patient.fullName).join(',');
            break;
        default:
            title = data.name;
            break;
    }
    return {
        key,
        title,
        titlePrefix,
        url: tabUrl,
        closable: true,
        subTabs,
        meta: tabData
    };
};
