export default {
  title: {
    list: 'Danh sách người dùng',
    edit: 'Chỉnh sửa người dùng',
    create: 'Thêm người dùng',
    changePassword: 'Đổi mật khẩu',
    createUser: 'Thêm người dùng'
  },
  filter: {
    username: 'Tìm theo tên đăng nhập',
    role: 'Tìm theo chức vụ',
    includeDisableUser: 'Bao gồm các người dùng đã khóa'
  },
  text: {
    loginInfo: 'Thông tin người dùng',
    demographicInfo: 'Thông tin người dùng',
    passwordInfo: 'Tạo mật khẩu',
    defaultPassword: 'Mật khẩu mặc định: iT123456'
  },
  notification: {
    duplicateUsername: 'Tên đăng nhập đã tồn tại. Vui lòng chọn tên đăng nhập khác',
    create: {
      success: 'Thêm người dùng mới thành công',
      failure: 'Không thể thêm người dùng. Vui lòng thử lại hoặc liên hệ quản trị viên.'
    },
    update: {
      success: 'Cập nhật thông tin người dùng thành công',
      failure: 'Không thể chỉnh sửa thông tin bệnh nhân. Vui lòng thử lại hoặc liên hệ quản trị viên.'
    },
    password: { update: 'Bạn có chắc chắn muốn thay đổi mật khẩu của người dùng <b>%{userName}?</b>' },
    updateModality: {
      success: 'Thêm nhóm máy chụp thành công',
      fail: 'Thêm máy chụp không thành công. Vui lòng thử lại hoặc liên hệ quản trị viên'
    }
  },
  button: {
    help: 'Trợ giúp'
  },
  mobile: {
    userInfo: 'Thông tin người dùng'
  }
};
