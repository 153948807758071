export default {
    name: 'Activity History',
    fields: {
        requestTime: 'Request time',
        username: 'Account name',
        fullName: 'Full name',
        auditLogType: 'Activity type',
        description: 'Description',
        added: 'Added',
        deleted: 'Deleted',
        reportBefore: 'Report before',
        reportAfter: 'Report after',
        reportApproved: 'Report approved',
        searchByUsername: 'Search by username'
    },
    messages: {
        HTTP_REQUEST: 'Actions default',
        LOGIN: 'Login',
        SEND_STUDY: 'Sent study of study %{studyId} - %{patientName}',
        APPROVE_REPORT: 'Approved report of study %{studyId} - %{patientName}',
        REJECT_REPORT: 'Reject report',
        OVERWRITE_REPORT: 'Saved report of study %{studyId} - %{patientName}',
        COMPLETE_REPORT: 'Completed report',
        REJECT_APPROVED_REPORT: 'Cancel approved report of study %{studyId} - %{patientName}'
    },
    actionType: {
        HTTP_REQUEST: 'Actions default',
        LOGIN: 'Login',
        SEND_STUDY: 'Sent study of study',
        APPROVE_REPORT: 'Approved report of study',
        REJECT_REPORT: 'Reject report',
        OVERWRITE_REPORT: 'Saved report of study',
        COMPLETE_REPORT: 'Completed report',
        REJECT_APPROVED_REPORT: 'Cancel approved report of study'
    }
};
