import { get, memoize } from 'lodash';
import moment from 'moment';

export const msgPrefix = 'validation.invalid';

export const inputValidate = {
  pid: { pattern: /^[A-Za-z\d\-!@#$%^&*.,/:]{3,}$/ },

  mid: { pattern: /^[A-Za-z_\-\d]{3,}$/ },
  patientName: {
    pattern:
      /^[a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ^@0-9\s]{3,}$/u
  },
  pn: {
    pattern:
      /^[a-zA-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽếềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ^@0-9\s]{3,}$/u
  },
  accessionNumber: { pattern: /^[A-Za-z\d!@#$%^&*.,/:]{3,}$/ },
  orderId: { pattern: /^[A-Za-z\d]{3,}$/ },
  hospitalGroupId: { pattern: /^[A-Za-z_\-\d]{3,}$/ },
  pdfTemplateId: { pattern: /^[A-Za-z_\-\d]{3,}$/ },
  nationalId: { pattern: /^[\d]{9,12}$/ },
  phone: { pattern: /^[\d]{9,11}$/ },
  username: { pattern: /^[A-Za-z0-9_.-]{1,50}$/ },
  password: {
    pattern: /^[A-Za-z0-9_]{6,50}$/
    // pattern: /^(?=.*[A-Z])(?=.*[`~!@#$%^*()-=_+[\]{}:";',./<>?])(?=.*[0-9])(?=.*[a-z]).{8,50}$/
    // pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
  },
  email: {
    // eslint-disable-next-line no-useless-escape
    pattern:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  port: { pattern: /^()([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/ },
  ip: { pattern: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/ },
  birthYear: {
    pattern: /^0*(19[0-9][0-9]|20[0-9][0-9])$/
  },
  age: {
    pattern: /^0*([0-9]|[1-8][0-9]|9[0-9]|1[0-9]{2}|200)$/
  }
};

const normalizeValue = (value) => (typeof value === 'string' && value.trim().replace(/[\s]+/g, ' ')) || value;

/**
 * @param {string} patternName - name of pattern using to test
 */
const validPattern = memoize((patternName) => (value) => {
  const { pattern } = inputValidate[patternName];
  const normalizeVal = normalizeValue(value);
  // return pattern.test(normalizeVal) ? undefined : props.translate(`${msgPrefix}.${patternName}`);
  return pattern.test(normalizeVal) ? undefined : `${msgPrefix}.${patternName}`;
});

// export const testUsername = validPattern("username");
export const testPid = validPattern('pid');
export const testMid = validPattern('mid');
export const testPatientName = validPattern('patientName');
export const testAccessionNumber = validPattern('accessionNumber');
export const testOrderId = validPattern('orderId');
export const testNationalId = validPattern('nationalId');
// export const testPhoneNumber = validPattern('phoneNumber');
export const testBirthDate =
  (message = `${msgPrefix}.birthDate`) =>
  (value) => {
    const date = moment(value);
    return date.isValid() && date.get('year') >= 1900 ? undefined : message;
  };
// export const testEmail = validPattern('email');
export const testPatternUsername = validPattern('username');
export const testPatternIP = validPattern('ip');
export const testPatternPort = validPattern('port');
export const testPatternPassword = validPattern('password');
export const testPasswordVerify = (field) => (value, allValues) => {
  const originValue = get(allValues, field);
  return value === originValue ? undefined : `${msgPrefix}.${field}Verify`;
};

const checkPatternIncludeNull = memoize((patternName) => (value) => {
  const { pattern } = inputValidate[patternName];
  const normalizeVal = normalizeValue(value);
  if (normalizeVal) {
    return pattern.test(normalizeVal) ? undefined : `${msgPrefix}.${patternName}`;
  }
  return '';
  // return pattern.test(normalizeVal) ? undefined : props.translate(`${msgPrefix}.${patternName}`);
});

export const testEmail = checkPatternIncludeNull('email');
export const testPhoneNumber = checkPatternIncludeNull('phone');
export const testBirthYear = checkPatternIncludeNull('birthYear');
export const testAge = checkPatternIncludeNull('age');
export const testHospitalGroupId = validPattern('hospitalGroupId');
export const testPdfTemplateId = validPattern('pdfTemplateId');
