export default {
    tab: {
        main: 'Đọc ảnh',
        receiptionist: 'Tiếp đón'
    },
    dashboard: {
        main: 'Báo cáo thống kê',
        studies: 'Tổng hợp ca chụp',
        doctor: 'Tổng hợp đọc ca',
        synthesis: 'Màn hình tổng hợp'
    },
    study: {
        main: 'Danh sách ca chụp',
        list: 'Tất cả ca chụp',
        unread: 'Ca chưa đọc',
        unconfirm: 'Ca chưa duyệt',
        confirmed: 'Ca đã duyệt',
        tele: 'Hội chẩn từ xa'
    },
    receiptionist: {
        title: 'Phòng chụp',
        main: 'Tất cả bệnh nhân',
        pending: 'Bệnh nhân chờ tiếp nhận',
        modalityInfo: ''
    },
    upload: {
        createSingle: 'Tải lên một ca chụp',
        createMulti: 'Tải lên nhiều ca chụp',
        create: 'Tạo ca chụp',
        created: 'Ca chụp đã tải lên',
        creating: 'Ca chụp đang tải lên'
    },
    registration: 'Danh sách chỉ định',
    examination: {
        done: 'Chỉ định hoàn thành',
        list: 'Danh sách chỉ định'
    },
    patient: 'Quản lý bệnh nhân',
    // order: 'Quản lý chỉ định',
    passwordChange: 'Đổi mật khẩu',
    profile: 'Thông tin cá nhân',
    modalitiesList: 'Danh sách máy chụp',
    administrator: {
        main: 'Quản lý thông tin',
        user: 'Người dùng',
        role: 'Chức vụ',
        authority: 'Phân quyền',
        modality: 'Thiết bị chụp',
        template: 'Mẫu nội dung KQ',
        department: 'Danh sách khoa',
        uploadStudy: 'Tải lên ca chụp',
        profile: 'Thông tin cá nhân',
        audit: 'Lịch sử hoạt động',
        service: 'Dịch vụ chụp',
        store: 'Lưu trữ PACS',
        createStudy: 'Tạo ca DICOM',
        createNonStudy: 'Tạo ca non-DICOM',
        generalCreateStudy: 'Tạo ca chụp',
        activity: 'Lịch sử hoạt động',
        group: 'Cấu hình hội chẩn',
        hospitalGroup: 'Nhóm bác sĩ',
        pdfTemplate: 'Mẫu hiển thị KQ',
        webcamNonDicom: 'Tạo ca non-DICOM từ máy'
    },
    technician: {
        main: 'Thông tin máy chụp'
    },
    scanning: {
        waiting: 'Màn hình chờ',
        studying: 'Danh sách đang chụp'
    },
    studyBookmark: {
        list: 'Thư mục cá nhân'
    },
    generatePdf: {
        list: 'Báo cáo thống kê'
    },
    order: {
        create: 'Tạo chỉ định'
    },
    registrationStats: {
        list: 'Màn hình theo dõi'
    },
    tabGroup: {
        main: 'Quản lý',
        resource: {
            user: 'Người dùng',
            modality: 'Máy chụp',
            department: 'Khoa phòng',
            role: 'Vai trò',
            modalityGroup: 'Nhóm máy',
            procedure: 'Dịch vụ chụp',
            template: 'Mẫu nội dung KQ',
            pdfTemplate: 'Mẫu hiển thị KQ'
        }
    }
};
