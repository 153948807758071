export default {
    title: {
        list: 'Studies Management',
        history: 'Studies history',
        show: 'Study Information',
        radiologistInfo: 'Radiologist Info',
        download: 'Download Study',
        downloadList: 'Download Studies List',
        similar: 'Studies of %{gender} patient with name <b>%{patientName}</b>'
    },
    mobile: {
        list: 'Studies List',
        show: 'Study Infomation'
    },
    mobileViewer: {
        name: 'Patient',
        fields: {

        }
    },
    label: {
        source: 'Source',
        fromDate: 'From',
        toDate: 'To',
        report: 'Report',
        images: 'Image',
        tele: 'IRad',
        receiveOrder: 'Receive',
        confirmReceive: 'Are you sure to receive %{patientName}\'s study?',
        hold: 'Time to hold (hours)',
        time: 'Hold on time',
        cancelReason: 'Cancel Reason',
        viewImage: 'View Image',
        assignee: 'Assignee',
        assign_of_mine: 'Me',
        assign_of_all: 'All',
        downloadFileType: 'Download file type',
        downloadLevel: 'Download level',
        packageType: 'Package type',
        splitSize: 'Split size',
        downloadTracking: 'The system is processing download data %{progress}',
        downloadTrackingFull: 'Download data have been processed %{progress}',
        page: 'Page',
        expiredTime: 'Expired time'
    },
    confirmReport: 'Do you want to confirm this report?',
    notification: {
        assign: {
            confirm: 'Are you sure to assign the study of %{patientName} to radiologist %{assigneeName}, and finish it before %{expiredTime}?',
            success: '%{patientName}\'s has assigned',
            fail: 'Cannot assign %{patientName}\'s study to radiologist %{assigneeName}',
            cancel: 'Are you sure to cancel this assignment?',
            unlock: 'Are you sure to unlock this study?',
            cancelSuccess: 'Your assignment has been cancelled!',
            unlockSuccess: 'This study has been unlocked!',
            missingOrder: 'Can\'t receive this study cause this study don\'t have order'
        },
        confirm: {
            before: 'Do you agree to confirm the diagnostic results for the %{patientName}\'s study?',
            success: 'The study of patient %{patientName} has been confirmed successful',
            continueConfirm: 'The study of patient %{patientName} has been confirmed successful. Do you want to unlock this study?',
            fail: 'Cannot confirm the result of diagnostic. Please contact administrators!'
        },
        toDraftReport: {
            confirm: 'Are you sure want to start edit this result?'
        },
        cancelConfirm: {
            // before: 'Bạn có đồng ý bỏ duyệt kết quả chẩn đoán cho ca chụp của bệnh nhân <b>%{patientName}</b>b không?',
            success: 'Kết quả chẩn đoán cho ca chụp của bệnh nhân <b>%{patientName}</b> đã được bỏ duyệt',
            fail: 'Không thể bỏ duyệt kết quả chẩn đoán. Vui lòng liên hệ quản trị viên'
        },
        download: 'The system is processing data. Please wait!',
        downloadError: 'Cannot download some studies.\n%{error}\nPlease try again or contact administrators .',
        addNewReport: {
            fail: 'Cannot add new report. Please try again later'
        },
        splitViewer: {
            notFoundStudy: 'Not found study information. Please try again or contact administrators.'
        },
        mapOrder: {
            confirm: 'Are you sure you want to map order to study?',
            success: 'Map order to study is successful',
            fail: 'Can\'t map order to study. Please try again or contact administrators!'
        },
        createStudy: {
            captureFail: 'Not yet connected image. Please try again',
            imageRequired: 'No photos have been taken yet. Please try again'
        },
        missingProcedure: 'You don\'t choose procedure for study!!'
    },
    download: {
        denied: 'You dont have permission for download DICOM data. Please contact the administrators!',
        type: {
            DICOM: 'Images (DICOM)',
            PNG: 'Images (PNG)',
            JPG: 'Images (JPG)',
            MP4: 'Video (MP4)'
        },
        package: {
            ZIP: 'ZIP Files',
            ISO: 'ISO Files'
        },
        level: {
            STUDY: 'Entire study',
            IMAGE: 'Current image'
        },
        nextUpdateTime: 'Next update time'
    },
    button: {
        downloadStudy: 'Download study',
        showInfo: 'Show Information',
        hideInfo: 'Hide Information',
        updateReport: 'Update report',
        view: 'View image',
        apply: 'Apply',
        unlock: 'Unlock study',
        compare: 'Compare',
        pushTele: 'Teleradiology',
        continue: 'Continue',
        splitviewer: 'Split viewer',
        studyInfo: 'Study information',
        viewDicomImage: 'DICOM image',
        dicomInfo: 'DICOM information',
        lockStudy: 'Lock study',
        couple: 'Couple',
        viewDicomImageNewTab: 'View study in new tab',
        cancelCreate: 'Cancel'
    }
};
