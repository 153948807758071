export default {
    name: 'Language',
    fields: {
        currentLanguage: 'Current language',
        pickLanguage: 'Pick a language'
    },
    mobile: {
        changeLanguage: 'Change language'
    }
};
