export default {
    name: 'Thiết bị chụp',
    title: {
        list: 'Danh sách',
        create: 'Thêm mới',
        edit: 'Thay đổi'
    },
    fields: {
        id: 'Mã thiết bị',
        title: 'Thiết bị chụp',
        name: 'Tên thiết bị',
        aeTitle: 'AE Title',
        ipAddress: 'Địa chỉ IP',
        port: 'Cổng',
        type: 'Loại thiết bị',
        description: 'Mô tả',
        modelName: 'Tên phiên bản',
        modalityType: 'Loại',
        rent: 'Thuê',
        modalityProviderId: 'Nhà cung cấp',
        isScanner: 'Là máy chụp',
        enabled: 'Hoạt động',
        roomNo: 'Số phòng',
        prefixStudyIUID: 'StudyIUID Prefix',
        supportWL: 'Hỗ trợ Worklist',
        nonDicomSendWL: 'Non-DICOM gửi ảnh'
    },
    notification: {
        duplicateModality: 'AE Title %{aeTitle} và Địa chỉ IP %{ipAddress} đã tồn tại. Vui lòng thử lại',
        cannotDelete: 'Không thể xóa các thông tin đang được sử dụng'
    },
    label: {
        today: 'Hôm nay',
        modality: 'Máy chụp',
        status: 'Tình trạng',
        totalStudies: 'Tổng ca',
        pending: 'Chờ khám'
    }
};
