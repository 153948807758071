export default {
    ALL: 'Tất cả',
    CT: 'CT',
    MR: 'Cộng hưởng từ',
    CR: 'X-Quang',
    DX: 'X-Quang',
    ES: 'Nội soi',
    ECG: 'Điện tim',
    XA: 'Chụp mạch X Quang',
    MG: 'Chụp nhũ ảnh',
    PT: 'PET CT',
    US: 'Siêu âm',
    OCT: 'Cắt lớp Quang học',
    RF: 'Chụp huỳnh quang',
    Other: 'Khác',
    OT: 'Máy khác'
};
