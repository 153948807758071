export const SET_MOBILE_CONFIG = 'mobile/SET_MOBILE_CONFIG';
export const UPDATE_META_MOBILE_CONFIG = 'mobile/UPDATE_META_MOBILE_CONFIG';
export const MOBILE_CONFIG_STATE = 'topbar-state';

export const setMobileConfig = ({ url, type, isHomePage, listOption, backURL, meta, resource }) => ({
  type: SET_MOBILE_CONFIG,
  payload: {
    url,
    type,
    resource,
    isHomePage,
    listOption,
    backURL,
    meta
  }
});

export const updateMetaMobileConfig = (meta) => ({
  type: UPDATE_META_MOBILE_CONFIG,
  payload: {
    meta
  }
});
