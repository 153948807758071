import { CLEAR_STATE } from 'react-admin';

import { CHECK_MOBILE_MODE_SUCCESS } from '../actions';

const initialState = { isMobile: false };

export default (previousState = initialState, action) => {
  const { type, payload } = action;
  if (type === CHECK_MOBILE_MODE_SUCCESS) {
    return { ...previousState, ...payload.data };
  }
  if (type === CLEAR_STATE) {
    return initialState;
  }
  return previousState;
};
