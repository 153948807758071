export const DOWNLOAD_STUDIES = 'IT/DOWNLOAD_DATA';

export const downloadStudies = (downloadData) => ({
    type: DOWNLOAD_STUDIES,
    payload: { data: downloadData }
});

export const DOWNLOAD_SINGLE_STUDY = 'IT/DOWNLOAD_SINGLE_STUDY';

export const downloadSingleStudy = ({ studyID }) => ({
    type: DOWNLOAD_SINGLE_STUDY,
    payload: { studyID }
});
