export default {
    name: 'Report',
    fields: {
        note: 'Note',
        conclusion: 'Conclusion',
        body: 'Report description',
        status: 'Status',
        completedTime: 'Completed Time',
        completed: 'Completed',
        draft: 'Draft',
        notAssigned: 'Not Assigned',
        approved: 'Approved',
        totalReports: 'Total (Studies)',
        todayReports: 'Today (Studies)',
        thisMonthReports: 'This month (Studies)',
        lastMonthReports: 'Last month (Studies)',
        oldReport: 'Old report',
        localReport: 'Internal Report',
        teleReport: 'Results of consultation',
        approvedTime: 'Approved time',
        reportTime: 'Report Time',
        yearOfBirth: 'Year of Birth',
        diagnosis: 'Diagnosis',
        reportNum: 'Report No.'
    },
    label: {
        radiologist: 'Radiologist',
        reportDate: 'Report date',
        status: 'Status',
        approverName: 'Approver name',
        approveDate: 'Approve date',
        approveReport: 'Approve report',
        chooseReport: 'Choose report',
        info: 'General information',
        addRadiologist: 'Add radiologist'
    },
    button: {
        saveReport: 'Save report',
        addReport: 'Add report'
    },
    pdf: {
        fontSize: 'Font size',
        lineHeight: 'Line height'
    }
};
