export default {
    notification: {
        create: {
            before: 'Do you want to create config of group %{groupName}',
            successful: 'Create config of group %{groupName} is successful',
            failure: 'Can\'t create config of group %{groupName}. Please try again or contact admin'
        },
        update: {
            before: 'Do you want to edit config of group %{groupName}',
            successful: 'Edit config of group %{groupName} is successful',
            failure: 'Can\'t edit config of group %{groupName}. Please try again or contact admin'
        }
    }
};
