// import React from 'react';
import { useKeyPress } from 'react-use';

// export const keyboardState = {};
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
localStorage.setItem('keyboardState', {});

const KeyboardStateHandler = (props) => {
    const { keys = [] } = props;
    const keyboardState = {};
    for (let i = 0; i < keys.length; ++i) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        keyboardState[keys[i]] = useKeyPress(keys[i])[0];
    }
    // keyboardState.Control = useKeyPress('Control')[0];
    localStorage.setItem('keyboardState', JSON.stringify(keyboardState));
    // console.log('KeyboardStateHandler');
    return null;
};

export default KeyboardStateHandler;
