export default {
  title: {
    show: 'Kết quả chẩn đoán',
    result: 'Kết quả chẩn đoán <b>%{patientName}</b> - <b>%{studyDate}</b>',
    annotationGroup: 'Lưu thông tin đo đạc'
  },
  mobile: {
    show: 'Kết quả chẩn đoán'
  },
  error: 'Không thể tải kết quả chẩn đoán. Hãy thử lại hoặc liên hệ quản trị viên!',
  loading: 'Đang tải kết quả chẩn đoán...',
  label: {
    draft: '(Bản nháp)',
    radiologist: 'Bác sĩ đọc',
    reportVersion: 'Phiên bản kết quả',
    teleReports: 'Kết quả hội chẩn',
    localReport: 'Kết quả nội bộ',
    teleDesination: 'Nơi nhận yêu cầu',
    log: 'Lịch sử kết quả hội chẩn',
    logLocal: 'Lịch sử kết quả nội bộ',
    attachments: 'Tệp đính kèm',
    approval: 'Đồng ý duyệt luôn kết quả chẩn đoán',
    procedure: 'Chỉ định: %{procedureName}'
  },
  notification: {
    save: 'Bạn có chắc chắn muốn lưu kết quả báo cáo của bệnh nhân %{patientName} không?',
    update: 'Bạn có chắc chắn muốn thay đổi nội dung kết quả chẩn đoán không?',
    error: 'Bạn có muốn sửa nội dung kết quả không?\nHãy bấm nút "Xác nhận" để sửa lại nội dung kết quả',
    confirm: {
      label: 'Bạn có đồng ý duyệt kết quả này không?',
      before: 'Bạn có đồng ý duyệt kết quả chẩn đoán cho ca chụp của bệnh nhân <b>%{patientName}</b> không?',
      success: 'Kết quả chẩn đoán cho ca chụp của bệnh nhân <b>%{patientName}</b> đã được duyệt',
      fail: 'Không thể duyệt kết quả chẩn đoán. Vui lòng liên hệ quản trị viên'
    },
    askConfirm: 'Hệ thống sẽ tự động duyệt kết quả chẩn đoán sau 60 phút. Bạn có muốn duyệt ngay kết quả chẩn đoán không?',
    expiredTime: 'Thời gian báo lỗi đã hết!!!',
    editApprovedReport: 'Bạn có chắc chắn muốn sửa kết quả chẩn đoán của bệnh nhân <b>%{patientName}</b> đã được duyệt không?',
    unApproveFail: 'Không thể hủy duyệt kết quả chẩn đoán. Vui lòng liên hệ quản trị viên',
    cantApprove: 'Chỉ định <b>%{procedureName}</b> đã có kết quả được duyệt! Vui lòng kiểm tra lại.',
    cantPrintReport: 'Không thể in báo cáo vào lúc này! Vui lòng liên hệ quản trị viên',
    cantCompleteReport: 'Chỉ định đã được duyệt không thể lưu báo cáo! Vui lòng kiểm tra lại.',
    mediaNotFound: 'Không thể kết nối thiết bị ghi âm',
    serverFailed: 'Không thể kết nối tới máy chủ',
    completeSuccess: 'Hoàn thành chẩn đoán ca <b>%{patientName}</b>'
  },
  button: {
    confirmReport: 'Duyệt kết quả',
    cancelAssign: 'Hủy nhận ca',
    printReport: 'In kết quả',
    attachment: 'Đính kèm',
    viewReport: 'Xem kết quả',
    selectAll: 'Chọn tất cả',
    unSelect: 'Bỏ chọn',
    reset: 'Khôi phục',
    record: 'Ghi âm',
    stopRecord: 'Dừng'
  }
};
