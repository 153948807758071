export const UPDATE_ARRAY = 'UPDATE_ARRAY';
export const CREATE_ARRAY = 'CREATE_ARRAY';
export const GET_EXTEND = 'GET_EXTEND';

export const USERS = 'user';
export const USER_MODALITY = 'user-modality';
export const CHANGE_PASSWORDS = 'me/password';
export const LANGUAGE = 'language';
export const CURRENT_USER = 'me';
export const ROLES = 'role';
export const AUTHORITIES = 'authority';
export const DEPARTMENT = 'department';
export const TITLE = 'title';
export const PROCEDURE = 'procedure';
export const STORE = 'storage';
export const UPLOADS = 'study-upload';
export const LOGS = 'logs';
export const SETTING = 'setting';

export const STUDY_BOOKMARK = 'bookmark';
export const STUDY_BOOKMARK_LABEL = 'bookmark-label';
export const STUDY_DOWNLOADS = 'study-download';

export const PATIENTS = 'patient';
export const PATIENT_IDENTIFIERS = 'identifiers';
export const PATIENT_PHONES = PATIENT_IDENTIFIERS;
export const PATIENT_EMAILS = PATIENT_IDENTIFIERS;
export const PATIENTS_ALL = [PATIENTS, PATIENT_IDENTIFIERS];

export const STUDY = 'study';
export const RESOURCE_BODY_PART = 'body-part';
export const ANNOTATION_GROUP = 'annotation-group';

export const MODALITIES = 'modality';
export const MODALITY_TYPE = 'modality-type';
export const MODALITY_GROUP = 'modality-group';
export const MODALITIES_ALL = [MODALITIES, MODALITY_TYPE, MODALITY_GROUP];
export const TEMPLATES = 'template';

export const VIEWERS = 'me/viewer';
export const ORDERS = 'order';
export const REPORTS = 'me/report';
export const SERVICES = 'service';

export const PATIENT_PORTAL_RESOURCE = [ORDERS, REPORTS, SERVICES];

export const TELE_CHECK_TOKEN = 'check_token';
export const TELE_GET_TOKEN = 'get_token';

// new study api
export const STUDY_REPORT_ATTACHMENT = 'report-attachment';
export const STUDY_REPORT_COMPLETED = 'report-completed';
export const STUDY_REPORT_APPROVE = 'report-approved';
export const STUDY_REPORT_PDF = 'study-report-pdf';
export const STUDY_PROCEDURE = 'study-service';
export const NEW_STUDY_ALL = [
  STUDY_REPORT_ATTACHMENT,
  STUDY_REPORT_COMPLETED,
  STUDY_REPORT_APPROVE,
  STUDY_REPORT_PDF,
  STUDY,
  STUDY_PROCEDURE
];

export const CONTENT_TEMPLATE = 'content-template';

export const ASYNC_API_ALL = [
  USERS,
  CURRENT_USER,
  CHANGE_PASSWORDS,
  USER_MODALITY,
  DEPARTMENT,
  ...MODALITIES_ALL,
  ROLES,
  RESOURCE_BODY_PART,
  PROCEDURE,
  CONTENT_TEMPLATE,
  ...NEW_STUDY_ALL,
  AUTHORITIES,
  ...PATIENTS_ALL,
  TITLE,
  STUDY_BOOKMARK,
  STUDY_BOOKMARK_LABEL,
  STUDY_DOWNLOADS
];

export const ADMIN_ALL = [...PATIENT_PORTAL_RESOURCE];
export const ANNOTATION_ALL = [ANNOTATION_GROUP];

export const RESOURCES = [...ADMIN_ALL, UPLOADS, ...ANNOTATION_ALL, ...ASYNC_API_ALL];
