import { result } from 'lodash';

export default {
  title: {
    list: 'Danh sách ca chụp',
    history: 'Lịch sử chụp',
    show: 'Thông tin ca chụp',
    radiologistInfo: 'Thông tin bác sĩ đọc ca',
    download: 'Tải ca chụp',
    downloadList: 'Danh sách ca tải về',
    similar: 'Các ca chụp của bệnh nhân <b>%{gender}</b> tên <b>%{patientName}</b>',
  },
  mobile: {
    list: 'Danh sách ca chụp',
    show: 'Thông tin ca chụp'
  },
  reportFields: {
    result: 'Kết quả chẩn đoán'
  },
  mobileViewer: {
    name: 'BN',
    fields: {}
  },
  label: {
    source: 'Nguồn',
    fromDate: 'Từ',
    toDate: 'Đến',
    report: 'Kết quả',
    images: 'Ảnh',
    tele: 'Hội chẩn',
    receiveOrder: 'Nhận ca',
    confirmReceive: 'Bạn có chắc chắn nhận ca của bệnh nhân <b>%{patientName}</b> không?',
    hold: 'Giữ ca trong vòng (giờ)',
    time: 'Thời gian giữ ca',
    cancelReason: 'Lý do hủy nhận ca',
    viewImage: 'Xem ảnh',
    assignee: 'Người duyệt',
    assign_of_mine: 'Ca của tôi',
    assign_of_all: 'Tất cả',
    downloadFileType: 'Định dạng',
    downloadLevel: 'Tải ca chụp',
    packageType: 'Phương thức tải về',
    splitSize: 'Kích thước',
    downloadTracking: 'Đang xử lý dữ liệu tải về %{progress}',
    downloadTrackingFull: 'Dữ liệu tải về đã hoàn tất %{progress}',
    page: 'Trang',
    expiredTime: 'Thời gian hết hạn'
  },
  confirmReport: 'Bạn có đồng ý duyệt kết quả này không?',
  notification: {
    assign: {
      confirm:
        'Bạn có chắc chắn muốn phân công ca của bệnh nhân <b>%{patientName}</b> cho bác sĩ <b>%{assigneeName}</b>, trả kết quả trước <b>%{expiredTime}</b> không?',
      success: 'Nhận ca <b>%{patientName}</b> thành công',
      fail: 'Không thể nhận ca <b>%{patientName}</b>',
      cancel: 'Bạn có chắc chắn muốn hủy ca đã nhận không?',
      unlock: 'Bạn có chắc chắn muốn mở khóa ca này không?',
      cancelSuccess: 'Hủy nhận ca <b>%{patientName}</b> thành công!',
      unlockSuccess: 'Mở khóa ca thành công!',
      missingOrder: 'Không thể nhận ca do ca chụp này chưa có chỉ định'
    },
    confirm: {
      before: 'Bạn có đồng ý duyệt kết quả chẩn đoán cho ca chụp của bệnh nhân <b>%{patientName}</b> không?',
      success: 'Duyệt ca <b>%{patientName}</b> thành công',
      continueConfirm: 'Kết quả chẩn đoán cho ca chụp của bệnh nhân <b>%{patientName}</b> đã được duyệt. Bạn có muốn mở khóa ca này không?',
      fail: 'Không thể duyệt kết quả chẩn đoán. Vui lòng liên hệ quản trị viên'
    },
    toDraftReport: {
      confirm: 'Bạn có chắc chắn muốn sửa lại kết quả này?'
    },
    cancelConfirm: {
      // before: 'Bạn có đồng ý bỏ duyệt kết quả chẩn đoán cho ca chụp của bệnh nhân <b>%{patientName}</b>b không?',
      success: 'Hủy duyệt ca <b>%{patientName}</b> thành công',
      fail: 'Không thể bỏ duyệt kết quả chẩn đoán. Vui lòng liên hệ quản trị viên'
    },
    download: 'Hệ thống đang xử lý dữ liệu của các ca chụp có thể tải. Xin vui lòng chờ!',
    downloadError: 'Không thể tải một số ca chụp.\n%{error}\nVui lòng thử loại hoặc liên hệ quản trị viên.',
    addNewReport: {
      fail: 'Không thể tạo kết quả mới vào lúc này. Vui lòng thử lại sau'
    },
    splitViewer: {
      notFoundStudy: 'Không có thông tin về ca chụp này. Vui lòng thử lại hoặc liên hệ quản trị viên'
    },
    mapOrder: {
      confirm: 'Bạn có chắc chắn ghép ca này?',
      success: 'Ghép ca chụp thành công',
      fail: 'Không thể ghép ca chụp. Vui lòng thử lại hoặc liên hệ quản trị viên'
    },
    createStudy: {
      captureFail: 'Chưa kết nối hình ảnh. Xin vui lòng thử lại',
      imageRequired: 'Chưa có ảnh nào được chụp. Xin vui lòng thử lại'
    },
    missingProcedure: 'Chưa chọn dịch vụ chụp!!'
  },
  download: {
    denied: 'Tài khoản không có quyền tải dữ liệu ca chụp. Xin vui lòng liên hệ quản trị viên!',
    type: {
      DICOM: 'Ảnh (DICOM)',
      JPG: 'Ảnh (JPG)',
      PNG: 'Ảnh (PNG)',
      MP4: 'Video (MP4)'
    },
    package: {
      ZIP: 'Lưu file',
      ISO: 'Xuất file in đĩa (ISO)'
    },
    level: {
      STUDY: 'Toàn bộ ca',
      IMAGE: 'Ảnh hiện tại'
    },
    nextUpdateTime: 'Cập nhật tiếp theo'
  },
  button: {
    downloadStudy: 'Tải ảnh DICOM',
    showInfo: 'Xem thông tin',
    hideInfo: 'Ẩn thông tin',
    updateReport: 'Cập nhật',
    view: 'Xem ảnh',
    apply: 'Đồng ý',
    unlock: 'Mở khóa ca',
    compare: 'So sánh',
    pushTele: 'Gửi hội chẩn',
    continue: 'Tiếp tục',
    splitviewer: 'Màn hình mở rộng',
    studyInfo: 'Thông tin ca',
    viewDicomImage: 'Xem ảnh',
    dicomInfo: 'Thông tin DICOM',
    lockStudy: 'Khóa ca',
    couple: 'Ghép ca',
    viewDicomImageNewTab: 'Xem ảnh ở tab mới',
    cancelCreate: 'Hủy nhận ca'
  }
};
