export default {
  notification: {
    delete: {
      before: 'Bạn có chắc chắn muốn xóa nhóm bác sĩ %{name}',
      successful: 'Đã xóa nhóm bác sĩ %{name}',
      failure: 'Không thể xóa nhóm bác sĩ %{name}. Vui lòng thử lại hoặc liên hệ quản trị viên'
    },
    create: {
      before: 'Bạn có chắc chắn muốn thêm nhóm bác sĩ này không?',
      successful: 'Thêm nhóm bác sĩ thành công',
      failure: 'Thêm nhóm bác sĩ thất bại. Vui lòng thử lại hoặc liên hệ quản trị viên',
      exist: 'Mã nhóm bác sĩ đã tồn tại. Hãy chọn mã nhóm bác sĩ khác'
    },
    update: {
      before: 'Bạn có chắc chắn muốn cập nhật thông tin cho nhóm bác sĩ %{name} không?',
      successful: 'Thông tin cho nhóm %{name} đã được cập nhật',
      failure: 'Cập nhật thông tin cho nhóm bác sĩ thất bại. Vui lòng thử lại hoặc liên hệ quản trị viên'
    },
    addMember: {
      failure: 'Không thể thêm bác sĩ vào nhóm. Vui lòng thử lại hoặc liên hệ quản trị viên'
    },
    invalid: {
      doctorNeeded: 'Vui lòng chọn bác sĩ cho nhóm',
      modalityNeeded: 'Vui lòng chọn máy chụp cho nhóm'
    }
  }
};
