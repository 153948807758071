import React from 'react';
import { useTranslate } from 'react-admin';
import { useTheme } from '@material-ui/styles';
import { Navbar } from 'react-bootstrap';


const NoAccess = () => {
  const translate = useTranslate();
  const theme = useTheme();
  return (
    <>
      <Navbar className="justify-content-center">
        <Navbar.Brand href="#" className="logoNav">
          <img
            className="d-inline-block align-top logo-hai-phong"
            src={theme && theme.logo.navbar.src}
            alt="logo"
          />
        </Navbar.Brand>
      </Navbar>

      <div className="w-100 h-100 d-flex text-center align-items-center justify-content-center">
        <div>{translate('no-access')}</div>
      </div>
    </>
  );
};

export default NoAccess;
