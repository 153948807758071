export const store = {
    name: 'PACS',
    title: {
        statistic: 'Thống kê dung lượng',
        manage: 'Quản lý ca lưu trữ',
        list: 'Danh sách các cơ sở dữ liệu DICOM'
    },
    fields: {
        status: 'Trạng thái',
        type: 'Loại',
        aeTitle: 'AETitle',
        host: 'Host',
        port: 'Port',
        used: 'Đã sử dụng(MB)',
        max: 'Tối đa(MB)',
        numStudy: 'Số ca',
        totalStudy: 'Số ca chụp hiện tại: ',
        totalSizeUsed: 'Dung lượng đã sử dụng (GB): ',
        maxSize: 'Dung lượng tối đa (GB): ',
        SIUID: 'Số  SIUID:'
    },
    notification: {
        delete: 'Bạn có chắc chắn muốn xóa ca chụp?'
    }
};

export default store;
