import { messages } from 'it-reactjs-ui-components';

import bodyPart from './bodyPart';
import modalityTypes from './modalityTypes';
import page from './page';
import resources from './resources';
import sidebar from './sidebar';
import viewer from './viewer';
import validate from './validate';

messages.vi.commons.message.delete = 'Bạn có chắc chắn muốn xóa %{resourceName}?';

export const vi = {
  ...messages.vi,
  viewer,
  page,
  sidebar,
  resources,
  priority: {
    0: 'Bình thường',
    1: 'Bình thường',
    2: 'Ca cấp cứu',
    3: '3',
    4: '4',
    5: '5'
  },
  order_priority: {
    routine: 'Không ưu tiên',
    urgent: 'Cấp cứu',
    stat: 'Khẩn cấp',
    scheduled: 'Khám dịch vụ'
  },
  bodyPart,
  ...modalityTypes,
  deleteReason: 'Nhập lý do xóa',
  'no-access': 'Đường link tra cứu hết hiệu lực hoặc không đúng. Vui Lòng liên hệ quản trị viên để được hỗ trợ.',
  '': '',
  title: {
    viewer: 'Ảnh',
    studies: 'Chi tiết'
  },
  confirm: {
    yes: 'Có',
    no: 'Không'
  },
  validation: validate,
  'max-tab': 'Các tab hiện tại chiếm quá nhiều bộ nhớ.\nVui lòng xóa bớt các tab khác để mở thêm'
};

export default vi;
