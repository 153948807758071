export const ITECH_REDUCER = 'iTech';

export const APP_CONFIG = 'appConfig';
export const CURRENT_USER = 'currentUser';
export const DOWNLOAD_STUDIES_DATA = 'downloadData';
export const TEMP_DATA_REDUCER = 'tempData';
export const TELE_AUTHORIZATION_REDUCER = 'teleAuth';
export const MOBILE_MODE = 'mobileMode';
export const MOBILE_CONFIG = 'mobileTopbar';
export const POPUP_STATUS = 'popupStatus';
export const MODALITY_INFO = 'modalityInfo';
