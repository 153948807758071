export default {
    button: {
        Zoom: 'Zoom',
        'Zoom In': 'Zoom In',
        'Zoom Out': 'Zoom Out',
        'Zoom to fit': 'Zoom to fit',
        Magnify: 'Magnify',
        Pan: 'Pan',
        'Stack Scroll': 'Stack scroll',
        Levels: 'Levels',
        'Invert B/W': 'Invert B/W',
        'Flip/Rotation': 'Flip/Rotation',
        'Flip Horizontal': 'Flip Horizontal',
        'Flip Vertical': 'Flip Vertical',
        Rotate: 'Rotate',
        Rotate90L: 'Rotate Left 90°',
        Rotate90R: 'Rotate Right 90°',
        Reset: 'Reset',
        Synchronize: 'Synchronize',
        SynchronizeSlice: 'Slice',
        SynchronizeSliceOffset: 'Slice offset',
        SynchronizeZoom: 'Zoom',
        SynchronizeWindow: 'Window',
        Slice: 'Slice',
        'WW/WL': 'WW/WL',
        Annotate: 'Annotate',
        Measurements: 'Measurements',
        MeasurementsList: 'Measurements list',
        MyMeasurementsList: 'My measurements list',
        SaveMyMeasurements: 'Save to my measurements list',
        Length: 'Length',
        Angle: 'Angle',
        CobbAngle: 'Cobb Angle',
        Bidirectional: 'RECIST',
        Ellipse: 'Elliptical ROI',
        CircleRoi: 'Circle ROI',
        Rectangle: 'Rectangular ROI',
        'Freehand ROI': 'Freehand ROI',
        'ROI Window': 'ROI Window',
        Brush: 'Brush',
        Probe: 'Probe',
        Eraser: 'Eraser',
        Reports: 'Reports',
        StudyInfo: 'Study information',
        Series: 'Series',
        Layout: 'Layout',
        CINE: 'CINE',
        Ruler: 'Ruler',
        Clear: 'Clear',
        View: 'View',
        MPR: 'MPR',
        '2D MPR': 'MPR normal',
        'PAPAYA MPR': 'MPR with GPU supported',
        Switch: 'Switch',
        Oblique: 'Oblique',
        Orthogonal: 'Orthogonal',
        'Draw Oblique Plane': 'Draw Oblique Plane',
        Window: 'WW/WL',
        Crosshair: 'Crosshair',
        'CT Abdomen': 'CT Abdomen (W: 350, L:50)',
        'CT Bone': 'CT Bone (W: 2500, L:500)',
        'CT Cerebrum': 'CT Cerebrum (W: 120, L:40)',
        'CT Liver': 'CT Liver (W: 150, L:50)',
        'CT Lung': 'CT Lung (W: 1500, L:-500)',
        'CT Mediastinum': 'CT Mediastinum (W: 300, L:50)',
        'CT Pelvis': 'CT Pelvis (W: 400, L:40)',
        'CT Posterior Fossa': 'CT Posterior Fossa (W: 250, L:80)',
        'References Lines': 'References Lines',
        Cornerstone: '2D',
        SaveAs: 'Export current image',
        CopyImage: 'Copy current image',
        Fullscreen: 'Fullscreen',
        '3DITK': '3D',
        Crop: 'Crop',
        IntensityProjection: 'MIP/MinIP/AvgIP',
        Anonymize: 'Anonymize patient data',
        InputWindow: 'Input window',
        SlabThickness: 'Slab thickness',
        Intensity: 'MIP',
        blendModes: 'Projection mode',
        ColorMap: 'Color Map',
        Relabel: 'Relabel',
        Description: 'Description',
        Delete: 'Delete',
        SaveMeasurement: 'Save Measurements',
        CMPR: 'C-MPR',
        'CMPR.Active': 'Modify curve',
        'CMPR.Inactive': 'Stop modifying curve',
        'CMPR.Clear': 'Delete curve',
        viewAnnotation: 'View',
        BookmarkStudy: 'Bookmark study',
        DeleteBookmark: 'Delete bookmark',
        Download: 'Download study',
        AnnotationToggle: 'Toggle Annotations',
        ImageQuality: 'Image quality',
        'ImageQuality.Best': 'Best',
        'ImageQuality.Good': 'Good',
        'ImageQuality.Medium': 'Medium',
        StudyShare: 'Share study',
        GetLossLessImage: 'Show high-quality image',
        StudiesHistory: 'Studies history',
        AddAttachment: 'Add to attachments list',
        ViewerSync: 'Sync viewers',
        CopyID: 'Copy ID',
        retry: 'Retry'
    },
    blendModes: {
        MIP: 'MIP',
        MinIP: 'MinIP',
        AvgIP: 'AvgIP'
    },
    message: {
        error: 'Cannot view images. Please try again or contact Administrators.',
        Downloading: 'Downloading',
        MPRBuild: 'Building MPR',
        volumeBuilding: 'Building volume image',
        SliceSpacingWarning: 'Calculated distances between slices are inconsistent, reconstructed images might be in incorrect proportions!',
        SwitchSeriesNotReconstructable: 'Selected series is not reconstructable!',
        saveAnnotationSuccess: 'Measurements of <b>%{fullName}</b> have been saved successfully.',
        copySuccess: 'Current image has been copied to clipboard.',
        copyError: 'Copy action is unsupported by browser',
        isHosting: 'broadcasting viewer to %{numClients} users.',
        isReceiving: 'receiving viewer state from %{hostID}.'
    },
    windowWidth: 'WW',
    windowCenter: 'WC',
    Measurements: 'Measurements',
    EditDescription: 'EditDescription',
    AssignLabel: 'Assign Label',
    saveSuccess: 'Measurements have been updated!',
    emptyViewport: 'Please drag a series here to view images.',
    images: 'img',
    status: 'Status',
    lastUpdated: 'Last updated on',
    annotation: {
        list: 'Saved Annotation Groups',
        saveTitle: 'Save annotation group',
        download: 'Download',
        createdTime: 'Created time',
        fullName: 'Creator name',
        save: 'Save',
        saveAs: 'Save As',
        addGroup: 'Add group',
        StudyDate: 'Study date'
    },
    viewerSharing: {
        title: 'Trình chiếu',
        hosting: 'Trình chiếu viewer cho  người dùng khác',
        hostingGuide: 'Hướng dẫn: copy mã ID và gửi cho người dùng muốn xem viewer, hệ thống sẽ tự thông báo khi có người dùng truy cập vào để xem viewer hiện tại.',
        viewing: 'Xem viewer của người dùng khác',
        viewingGuide: 'Hướng dẫn: mở cửa sổ xem trình chiếu và nhập mã ID của người chiếu gửi cho bạn, hệ thống sẽ tự đồng bộ với viewer của người chiếu.',
        presenter: 'Người trình bày',
        openViewingWindow: 'Mở cửa sổ xem trình chiếu',
        enterHostID: 'Nhập mã ID của người trình chiếu',
        destroy: 'Ngắt kết nối',
        minimize: 'Thu nhỏ'
    }
};
