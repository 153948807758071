export default {
    name: 'Kết quả',
    fields: {
        note: 'Ghi chú',
        conclusion: 'Kết luận',
        body: 'Nội dung kết quả',
        status: 'Trạng thái',

        completedTime: 'Ngày chẩn đoán',
        approvedTime: 'Ngày duyệt',
        completed: 'Chờ duyệt',
        draft: 'Bản nháp',
        notAssigned: 'Chưa nhận ca',
        approved: 'Đã duyệt',
        totalReports: 'Tổng (ca)',
        todayReports: 'Hôm nay (ca)',
        thisMonthReports: 'Tháng này (ca)',
        lastMonthReports: 'Tháng trước (ca)',
        oldReport: 'Kết quả cũ',
        localReport: 'Kết quả nội bộ',
        teleReport: 'Kết quả hội chẩn',
        reportTime: 'Thời điểm trả kết quả',
        yearOfBirth: 'Năm sinh',
        diagnosis: 'Chẩn đoán lâm sàng',
        reportNum: 'Lần'
    },
    label: {
        radiologist: 'Bác sĩ đọc',
        reportDate: 'Ngày đọc',
        status: 'Trạng thái',
        approverName: 'Bác sĩ duyệt',
        approveDate: 'Ngày duyệt',
        approveReport: 'Duyệt kết quả',
        chooseReport: 'Chọn kết quả',
        info: 'Thông tin chung',
        addRadiologist: 'Thêm bác sĩ chuyên khoa'
    },
    button: {
        saveReport: 'Lưu báo cáo',
        addReport: 'Thêm kết quả'
    },
    pdf: {
        fontSize: 'Cỡ chữ',
        lineHeight: 'Cách dòng'
    }
};
