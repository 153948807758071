import {
    ANNOTATION_GROUP,
    CHANGE_PASSWORDS,
    MODALITIES,
    STUDY,
    USERS,
    STORE,
    ROLES,
    SETTING,
    LANGUAGE
} from '../../../resources/resources';
import { account } from './account';
import annotationGroup from './annotationGroup';
import { assignments } from './assignments';
import { authorities } from './authorities';
import department from './department';
import { gender } from './gender';
import { identifiers } from './identifiers';
import logs from './logs';
import modalities from './modalities';
import { passwords } from './passwords';
import { patients } from './patients';
import { procedure } from './procedure';
import report from './report';
import role from './role';
import study from './study';
import { users } from './users';
import { store } from './store';
import setting from './setting';
import language from './language';
import viewer from './viewer';

export default {
    [ANNOTATION_GROUP]: annotationGroup,
    [USERS]: users,
    [ROLES]: role,
    authorities,
    patients,
    [MODALITIES]: modalities,
    [STUDY]: study,
    study_assignments: { ...study },
    gender,
    identifiers,
    report,
    assignments,
    [CHANGE_PASSWORDS]: passwords,
    logs,
    department,
    procedure,
    account,
    [STORE]: store,
    [SETTING]: setting,
    [LANGUAGE]: language,
    viewer
};
