const appName = process.env.REACT_APP_NAME;
export const AUTH_USER = `${appName}.us`;
export const AUTH_ID = `${appName}.ai`;
export const AUTH_BASIC = `${appName}.ab`;
export const AUTH_TOKEN = `${appName}.at`;
export const AUTH_USERNAME = `${appName}.aun`;
export const AUTH_AUTHORITIES = `${appName}.aaut`;
export const AUTH_ROLES = `${appName}.ar`;
// export const AUTH_REFRESH_TOKEN = `${appName}.art`;
// export const AUTH_SCOPE = `${appName}.as`;
export const AUTH_REMEMBER = `${appName}.arem`;
// export const AUTH_STAFF = `${appName}.astf`;
// export const AUTH_EXPIRE_IN = `${appName}.aei`;
export const MAIN_STATE = `${appName}.ms`;
export const DOWNLOAD_DATA = `${appName}.dd`;
export const SAVE_TEMP_DATA = `${appName}.tmpdt`;
export const POPUP_FILTER = `${appName}.pf`;

export const PERMISSIONS = {
  SYSTEM_MANAGER: 'SYSTEM_MANAGER', // Quản trị hệ thống
  DICOM_TRANSFER: 'DICOM_TRANSFER', // DICOM acquisition
  TECHNICIAN: 'TECHNICIAN', // Kỹ thuật viên
  ADMIN_DOCTOR: 'ADMIN_DOCTOR', // Lãnh đạo khoa
  RADIOLOGIST: 'RADIOLOGIST', // Bác sỹ chẩn đoán hình ảnh
  INTERN_DOCTOR: 'INTERN_DOCTOR', // Bác sỹ thực tập
  REFER_DOCTOR: 'REFER_DOCTOR', // Bác sỹ lầm sàng,
  RECEIPTIONIST: 'RECEIPTIONIST' // Nhan vien le tan
};

export const Roles = {
  ROLE_RADIOLOGIST: 'ROLE_RADIOLOGIST',
  ROLE_CLINICIAN: 'ROLE_CLINICIAN',
  ROLE_RECEPTIONIST: 'ROLE_RECEPTIONIST',
  ROLE_TECHNICIAN: 'ROLE_TECHNICIAN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_BOD: 'ROLE_BOD',
  ROLE_INTERN: 'ROLE_INTERN',
  ROLE_SYSADMIN: 'sysadmin'
};

export const ALL_AUTHORITIES = {
  SYSADMIN: 'SYSADMIN',
  USER_GET: 'USER_GET',
  USER_MANAGE: 'USER_MANAGE',
  ROLE_GET: 'ROLE_GET',
  ROLE_MANAGE: 'ROLE_MANAGE',
  DEPARTMENT_GET: 'DEPARTMENT_GET',
  DEPARTMENT_MANAGE: 'DEPARTMENT_MANAGE',
  MODALITY_GET: 'MODALITY_GET',
  MODALITY_MANAGE: 'MODALITY_MANAGE',
  PATIENT_GET: 'PATIENT_GET',
  PATIENT_MANAGE: 'PATIENT_MANAGE',
  PROCEDURE_GET: 'PROCEDURE_GET',
  PROCEDURE_MANAGE: 'PROCEDURE_MANAGE',
  CONTENT_TEMPLATE_GET: 'CONTENT_TEMPLATE_GET',
  CONTENT_TEMPLATE_MANAGE: 'CONTENT_TEMPLATE_MANAGE',
  LAYOUT_TEMPLATE_GET: 'LAYOUT_TEMPLATE_GET',
  LAYOUT_TEMPLATE_MANAGE: 'LAYOUT_TEMPLATE_MANAGE',
  ORDER_GET: 'ORDER_GET',
  ORDER_MANAGE: 'ORDER_MANAGE',
  STUDY_GET: 'STUDY_GET',
  STUDY_MANAGE: 'STUDY_MANAGE',
  STUDY_WADO: 'STUDY_WADO',
  STUDY_DOWNLOAD: 'STUDY_DOWNLOAD',
  STUDY_UPLOAD: 'STUDY_UPLOAD',
  REPORT_GET: 'REPORT_GET',
  REPORT_CREATE: 'REPORT_CREATE',
  REPORT_APPROVE: 'REPORT_APPROVE',
  ANNOTATION_GET: 'ANNOTATION_GET',
  ANNOTATION_MANAGE: 'ANNOTATION_MANAGE',
  BOOKMARK_GET: 'BOOKMARK_GET',
  BOOKMARK_MANAGE: 'BOOKMARK_MANAGE',
  ACTIVITY_GET: 'ACTIVITY_GET',
  STATS_LIVE_GET: 'STATS_LIVE_GET',
  STATS_INDIVIDUAL_GET: 'STATS_INDIVIDUAL_GET',
  STATS_GROUP_GET: 'STATS_GROUP_GET',
  STATS_ALL_GET: 'STATS_ALL_GET',
  TELERAD_GET: 'TELERAD_GET',
  TELERAD_CREATE: 'TELERAD_CREAT',
  STORAGE_GET: 'STORAGE_GET',
  REPORT_PDF_GET: 'REPORT_PDF_GET'
};

export const ALL_SAVE = [
  AUTH_ID,
  AUTH_BASIC,
  AUTH_TOKEN,
  AUTH_REMEMBER,
  AUTH_USERNAME,
  MAIN_STATE,
  AUTH_USER,
  DOWNLOAD_DATA,
  SAVE_TEMP_DATA
];
