export default {
  title: {
    list: 'Danh sách chỉ định',
    edit: 'Sửa thông tin chỉ định',
    create: 'Tạo chỉ định chụp',
    patientInfo: 'Thông tin bệnh nhân',
    request: 'Thông tin yêu cầu',
    procedure: 'Thông tin chiếu chụp',
    info: 'Thông tin chỉ định',
    findOrder: 'Tìm kiếm chỉ định'
  },
  notification: {
    patientNotFound: 'Không tìm thấy thông tin bệnh nhân!',
    studyNotFound: 'Hệ thống chưa ghi nhận dữ liệu ảnh của chỉ định %{accessionNumber}. Vui lòng thử lại sau!',
    duplicateOrderId: 'Mã chỉ định đã tồn tại. Hãy nhập mã chỉ định mới',
    orderNotFound: 'Không tìm thấy thông tin chỉ định',
    confirm: 'Xác nhận hoàn thành chỉ định của bệnh nhân <b>%{patientName}</b>?',
    confirmSuccess: 'Chỉ định của bệnh nhân <b>%{patientName}</b> đã được hoàn tất.',
    confirmError: 'Xác nhận huỷ chỉ định của bệnh nhân <b>%{patientName}</b>?',
    confirmErrorSuccess: 'Chỉ định của bệnh nhân <b>%{patientName}</b> đã được huỷ.',
    inprogress: 'Ca chụp đang được tiến hành',
    notStarted: 'Ca chụp chưa được bắt đàu',
    notChooseModality: 'Vui lòng chọn thiết bị chụp',
    create: {
      missingProcedure: 'Chưa chọn dịch vụ chụp',
      missingModality: 'Chưa chọn máy chụp',
      failure: 'Không thể tạo chỉ định chụp. Vui lòng thử lại hoặc liên hệ quản trị viên',
      successful: 'Thêm chỉ định thành công'
    },
    schedule: {
      failure: 'Không thể xếp lịch chụp cho bệnh nhân <b>%{patientName}</b>. Vui lòng thử lại hoặc liên hệ quản trị viên',
      successful: 'Xếp lịch chụp thành công cho bệnh nhân <b>%{patientName}</b>',
      updateFailure: 'Không thể cập nhật lịch chụp cho bệnh nhân <b>%{patientName}</b>. Vui lòng thử lại hoặc liên hệ quản trị viên',
      updateSuccessful: 'Cập nhật lịch chụp cho bệnh nhân <b>%{patientName}</b> thành công'
    },
    update: {
      success: 'Chỉ định đã được cập nhật thành công!'
    },
    complete: {
      success: 'Chỉ định đã được hoàn thành!'
    },
    suspend: {
      success: 'Chỉ định đã bị qua lượt!'
    },
    cancel: {
      success: 'Đã hủy dịch vụ thành công!'
    }
  }
};
