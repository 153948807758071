export const procedure = {
    name: 'Dịch vụ',
    title: {
        list: 'Danh sách',
        create: 'Thêm mới',
        edit: 'Thay đổi'
    },
    fields: {
        name: 'Dịch vụ chụp',
        other: 'Dịch vụ khác',
        modalityType: 'Thiết bị',
        modalityTypeService: 'Thiết bị chụp',
        bodyPartService: 'Bộ phận chụp',
        bodyPart: 'Bộ phận',
        serviceId: 'Mã dịch vụ',
        serviceName: 'Tên dịch vụ',
        preferred: {
            title: 'Ưu tiên',
            isPreferred: 'Có',
            nonPreferred: 'Không'
        },
        procedureCode: 'Mã dịch vụ',
        studyDescription: 'Mô tả DICOM'
    },
    notification: {
        duplicateCode: 'Mã dịch vụ %{procedureCode} đã tồn tại. Vui lòng thử lại'
    },
    action: {
        choose: 'Chọn dịch vụ chụp'
    },
    label: {
        tableTemplate: 'Các mẫu nội dung kết quả dịch vụ'
    }
};

export default procedure;
