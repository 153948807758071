import { createGenerateClassName } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createAdminStore, LazyLoad, MessageBox, CustomThemeProvider } from 'it-reactjs-ui-components';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import React, { Suspense, useState, useCallback } from 'react';
import { AuthContext, DataProviderContext, TranslationProvider } from 'react-admin';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { initMessageListener } from 'redux-state-sync';
import { PersistGate } from 'redux-persist/integration/react';

import { index } from './configurations/messages';
import ITechReducers from './configurations/reducers';
import { ITECH_REDUCER } from './configurations/reducers/reducerKeys';
import AuthProvider from './configurations/resources/AuthProvider';
import DataProvider from './configurations/resources/DataProvider';
import KeyboardStateHandler from './components/KeyboardStateHandler';
import customSideEffect from './configurations/sideEffects';
import NoAccess from './pages/noAccess/NoAccess';
import { LazyAuthenticator, PATIENT } from './pages/RoutingConfig';
import initializeThemes from './utils/initializeThemes';
// import { iTechLogoIcon } from './configurations/logoString';


const currentLanguage = localStorage.getItem('saveLocale') || 'vi';
const i18nProvider = polyglotI18nProvider((locale) => index[locale], currentLanguage);
const history = createBrowserHistory();
const customReducer = {
  [ITECH_REDUCER]: ITechReducers };
// const { iTechLogoIcon } = LogoString;

// const themes = initializeThemes({
//     logo: {
//         navbar: {
//             type: 'svg',
//             src: iTechLogoIcon
//         },
//         login: {
//             type: 'svg',
//             src: iTechLogoIcon
//         },
//         waitingOrder: {
//             type: 'svg',
//             src: iTechLogoIcon
//         },
//         icon: {
//             type: 'svg',
//             src: iTechLogoIcon
//         }
//     }
// }, { documentTitle: 'iTech PACS' });
const themes = initializeThemes();

const { store: adminStore, persistor } = createAdminStore({
  authProvider: AuthProvider,
  dataProvider: DataProvider,
  history,
  customReducer,
  customSideEffect
});
// cau hinh sync store giua cac tab
initMessageListener(adminStore);

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'Bass',
  seed: 'bs'
});

const keyboardWatchList = ['Control', 'Shift'];

const App = () => {
  // const themeType = useSelector((state) => state.themeType);
  const [currentTheme, setCurrentTheme] = useState(themes.light);

  const handleSwitchTheme = useCallback((themeType) => {
    setCurrentTheme(themeType === 'dark' ? themes.dark : themes.light);
  }, []);

  // console.log({ currentTheme });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <Provider store={adminStore}>
        <PersistGate loading={<LazyLoad />} persistor={persistor}>
          <Suspense fallback={<></>}>
            <AuthContext.Provider value={AuthProvider}>
              <DataProviderContext.Provider value={DataProvider}>
                <TranslationProvider i18nProvider={i18nProvider}>
                  <CustomThemeProvider theme={currentTheme}>
                    <ConnectedRouter history={history}>
                      <LastLocationProvider>
                        <>
                          <Switch>
                            {/* <Route exact path="/login" render={(props) => <LazyLogin {...props} />} /> */}
                            {
                              /**
                               * Now we only login by patient ID + date
                               */
                            }
                            <Route
                              path={`${PATIENT}/:orderDate/:id/:pid`}
                              exact
                              render={(props) => (
                                <LazyAuthenticator {...props} currentTheme={currentTheme} handleSwitchTheme={handleSwitchTheme} />
                              )}
                            />
                            <Route path="/" component={NoAccess} />

                          </Switch>
                          <MessageBox />
                          <KeyboardStateHandler keys={keyboardWatchList} />
                        </>
                      </LastLocationProvider>
                    </ConnectedRouter>
                  </CustomThemeProvider>
                </TranslationProvider>
              </DataProviderContext.Provider>
            </AuthContext.Provider>
          </Suspense>
        </PersistGate>
      </Provider>
    </StylesProvider>
  );
};

export default App;
