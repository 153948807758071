import { CLEAR_STATE } from 'react-admin';

import { DOWNLOAD_STUDIES } from '../actions';
import { DOWNLOAD_DATA } from '../resources/authUtils';

const initialState = JSON.parse(localStorage.getItem(DOWNLOAD_DATA)) || {
    ids: [],
    data: {},
    openStudyTracking: false
};

export default (previousState = initialState, action) => {
    const { type, payload } = action;
    if (type === DOWNLOAD_STUDIES) {
        const { data = {} } = payload;
        const newState = { ...previousState, ...data };
        localStorage.setItem(DOWNLOAD_DATA, JSON.stringify(newState));
        return newState;
    }
    if (type === CLEAR_STATE) {
        const emptyDownload = { ids: [], data: {}, openStudyTracking: false };
        localStorage.setItem(DOWNLOAD_DATA, JSON.stringify(emptyDownload));
        return emptyDownload;
    }
    return previousState;
};
