export default {
    notification: {
        create: {
            before: 'Bạn có chắc chắn muốn thêm cấu hình cho nhóm %{groupName}',
            successful: 'Thành công thêm cấu hình cho nhóm %{groupName}',
            failure: 'Không thể thêm cấu hình cho nhóm %{groupName}. Xin vui lòng thử lại hoặc liên hệ quản trị viên'
        },
        update: {
            before: 'Bạn có chắc chắn muốn thay đổi cấu hình cho nhóm %{groupName}',
            successful: 'Thành công thay đổi cấu hình cho nhóm %{groupName}',
            failure: 'Không thể thay đổi cấu hình cho nhóm %{groupName}. Xin vui lòng thử lại hoặc liên hệ quản trị viên'
        }
    }
};
