import { USERS, SETTING, CHANGE_PASSWORDS, LANGUAGE } from '../../../resources/resources';

import attachment from './attachment';
import audits from './audits';
import authority from './authority';
import dashboard from './dashboard';
import examination from './examination';
import login from './login';
import modality from './modality';
import order from './order';
import password from './password';
import patient from './patient';
import profile from './profile';
import report from './report';
import role from './role';
import study from './study';
import template from './template';
import uploads from './uploads';
import users from './users';
import department from './department';
import procedure from './procedure';
import registration from './registration';
import groupConfig from './groupConfig';
import label from './label';
import hospitalGroup from './hospitalGroup';
import language from './language';
import setting from './setting';
import mwl from './mwl';

export default {
  attachment,
  audits,
  authority,
  dashboard,
  examination,
  login,
  modality,
  order,
  mwl,
  password,
  [CHANGE_PASSWORDS]: password,
  patient,
  profile,
  report,
  role,
  study,
  template,
  uploads,
  [USERS]: users,
  department,
  procedure,
  registration,
  groupConfig,
  label,
  hospitalGroup,
  [LANGUAGE]: language,
  [SETTING]: setting
};
