export const patients = {
    name: 'Patient',
    fields: {
        id: 'Patient ID',
        pid: 'Patient ID',
        pidShortHand: 'Patient ID',
        gender: 'Gender',
        sex: 'Gender',
        fullName: 'Patient Name',
        patientName: 'Patient Name',
        name: 'Name',
        patientAddresses: 'Address',
        patientIdentifiers: 'Identifiers',
        patientPhones: 'Phones',
        patientEmails: 'Emails',
        patientNationalIds: 'National Id',
        patientPassports: 'Passport',
        birthDate: 'Date of birth',
        age: 'Age',
        estimated: 'Estimated',
        preferred: 'Preferred',
        address1: 'Address line 1',
        address2: 'Address line 2',
        address3: 'Address',
        city: 'Province/City',
        district: 'District',
        country: 'Country',
        postalCode: 'Postal code',
        verified: 'Verified',
        phone: 'Phone number',
        countryCode: 'Country Code',
        email: 'Email',
        address: 'Addresses',
        identifierType: 'IdentifierType',
        identifier: 'Identifier',
        addressComp: 'Address Comp',
        foreigner: 'Foreigner',
        identificationCard: 'Số CMTND',
        assuranceNumber: 'Assurance Number',
        nation: 'Ethnic',
        job: 'Job',
        allergyNotes: 'Dị ứng/Chống chỉ định',
        birthYear: 'Birth year'
    },
    entity: {
        title: 'Object',
        assurance: 'Assurance',
        fee: 'Fee',
        free: 'Free',
        other: 'Other'
    }
};

export default patients;
