export default {
    invalid: {
        required: {
            pid: 'Patient ID is required',
            patientName: 'Patient name is required',
            birthDate: 'Date of birth is required with format dd-MM-YYYY',
            gender: 'Gender is required',
            age: 'Age is required',
            accessionNumber: 'Accession number is required',

            mid: 'Modality is required',
            modality: 'Modality is required',
            modalityName: 'Modality name is required',
            modalityType: 'Modality type is required',
            modalityAe: 'AE Title is required',
            modalityIp: 'IP Address is required',
            modalityPort: 'Port is required',
            modalityGroupName: 'Modality group name is required',
            listModalities: 'List modalities are required',

            orderId: 'Order ID is required',
            orderDate: 'Order date is required',
            scheduleTime: 'Schedule time is required',
            diagnosis: 'Diagnosis is required',
            department: 'Department is required',
            departmentId: 'Department ID is required',
            referringPhysician: 'Referring physician is required',
            contrastMedia: 'Using contrast media information is required',
            priority: 'Priority is required',
            status: 'Status is required',
            protocol: 'Protocol is required',

            bodyPart: 'Body part is required',

            username: 'Username is required',
            password: 'Password is required',
            passwordVerify: 'Verified password is required',
            newPassword: ' New password is required',
            fullName: 'Full name is required',
            phone: 'Phone is required',
            email: 'Email is required',
            degree: 'Degree is required',

            templateName: 'Template name is required',
            authority: 'Authority is required',
            authorityId: 'Authority ID is required',
            roleId: 'Role ID is required',
            roleName: 'Role name is required',
            roleAuthority: 'Authorities are required',


            groupId: 'Target group of TeleRad id required',

            serviceId: 'Service ID is required',
            serviceName: 'Service name is required',
            registrationNumber: 'Registration number is required',
            birthYear: 'Birth year is required',
            hospitalGroupName: 'Hospital group name is required',
            hospitalGroupId: 'Hospital group code is required',
            description: 'Description is required',
            pdfTemplateId: 'Pdf Template ID is required',
            pdfTemplateName: 'Template name is required'
        },
        ip: 'Invalid IP address',
        port: 'Invalid Port number (1-65535)',
        pid: 'Patient Id must have at least 3 characters, including letters and digits, and special characters',
        mid: 'Modality must have at least 3 characters, including only letters and digits',


        orderId: 'Mã chỉ định chụp có ít nhất 3 kí tự, chỉ bao gồm chữ cái, không chứa kí tự đặc biệt',
        patientName: 'Patient Name must have at least 3 characters, including only letters and digits, not including special characters',
        pn: 'Patient Name must have at least 3 characters, including only letters and digits, not including special characters',
        accessionNumber: 'Accession Number must have at least 3 characters, including letters and digits, and special characters',
        nationalId: 'National Id are 9-12 characters, including only letters and digits, not including special characters',
        phone: 'Phone Number are 9-12 characters, including only numbers',
        brand: 'Brand Name must have at least 3 characters, including only letters and digits',
        birthDate: 'Year must be equal or greater than 1900',

        username: 'Username must not be longer than 50 characters, including only letters and digits. Please try again.',
        password: 'Passwords must be 8 characters long, including uppercase letters, lowercase letters, and at least one digit. Please try again.',
        passwordVerify: 'Verified password does not match with password. Please try again.',
        newPasswordVerify: 'Verified password does not match with new password. Please try again.',
        email: 'Email format is not valid',
        birthYear: 'Birth year format is not valid',
        age: 'Age must be between 0 and 200',
        hospitalGroupId: 'Hospital group id must have at least 3 characters, including only letter and digits, not including special characters',
        pdfTemplateId: 'Pdf template id must have at least 3 characters, including only letter and digits, not including special characters'
    }
};
