export default {
  notification: {
    checkPassFail: 'Mật khẩu hiện tại của bạn không chính xác. Xin hãy thử lại.',
    duplicate: 'Mật khẩu mới đang trùng với mật khẩu cũ. Vui lòng thử lại.'
  },
  mobile: {
    changePassword: 'Đổi mật khẩu'
  },
  label: {
    confirm: 'Đồng ý',
    back: 'Trở lại',
    success: 'Xác nhận'
  }
};
