export default {
    notification: {
        delete: {
            before: 'Do you want to delete group %{name}',
            successful: 'Deleted group %{name}',
            failure: 'Can\'t delete group %{name}. Please try again or contact admin'
        },
        create: {
            before: 'Do you want to create group?',
            doctorNeeded: 'Please choose doctor for hospital group',
            modalityNeeded: 'Please choose modality for hospital group',
            successful: 'New hospital group has been added',
            failure: 'Can\'t create hospital group. Please try again or contact admin',
            exist: 'Group code already exists. Please try other group code'
        },
        update: {
            before: 'Do you want to update hospital group information?',
            successful: 'The hospital group information has been updated',
            failure: 'Can\'t update hospital group information. Please try again or contact admin'
        },
        addMember: {
            failure: 'Can\'t add doctor to group. Please try again or contact admin'
        }
    }
};
