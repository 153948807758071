import { push, replace } from 'connected-react-router';
import { clone, find, findIndex, get } from 'lodash';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { generateTab } from '../../utils/generateTab';
import {
    activeTabSaga as activeTabSagaAction,
    ACTIVE_TAB_SAGA,
    addTab,
    ADD_TAB_SAGA,
    closeTab,
    CLOSE_TAB_SAGA,
    REPLACE_TAB_SAGA,
    selectTab,
    SELECT_TAB_SAGA,
    setLastHomeUrl,
    setListTab,
    setTabKey,
    updateTab,
    REPLACE_CURRENT_TAB,
    replaceTab,
    setSubLastHomeUrl
} from '../actions/tab';
import { LOCALE_EN, LOCALE_VI } from '../messages';
import { getListTab, getTab, getTabKey, getCurrentLocation } from '../selectors/tab';

function* handleAddTab(action) {
    const { tabData, basePath } = action.payload;
    if (!tabData) {
        return;
    }
    const newTab = generateTab(tabData, basePath);
    const { key } = newTab;
    const listTab = yield select(getListTab);
    const tab = find(listTab, { key });
    if (tab) {
        // can update tab truoc khi active
        yield put(updateTab({ tabData, basePath }));
        yield put(activeTabSagaAction({ key, tab: newTab })); // object tab ko tu update sau khi updateTab, phai chu dong dung newTab de tranh update lai thong tin tab cu
    } else {
        yield put(addTab(newTab));
        yield put(activeTabSagaAction({ key, tab: newTab }));
    }
    // else if (listTab.length === 10) {
    //     yield put(showNotification('max-tab', 'warning'));
    // }
}

function* handleActiveTab(action) {
    // console.log('handleActiveTab action', action);
    const { key, tab } = action.payload;
    const tabKey = yield select(getTabKey);
    if (tabKey === 'home') {
        const currentLocation = yield select(getCurrentLocation);
        // console.log('currentLocation', currentLocation);
        const url = currentLocation || tab.meta.data.lastHomeUrl;
        yield put(setLastHomeUrl(url));
    } else if (tabKey === 'subHome') {
        const currentLocation = yield select(getCurrentLocation);
        // console.log('currentLocation', currentLocation);
        const url = currentLocation || tab.meta.data.subLastHomeUrl;
        yield put(setSubLastHomeUrl(url));
    }
    yield put(setTabKey(key));
    yield put(push(tab.url));
}

function* handleSelectTab(action) {
    const { key } = action.payload;
    const tabState = yield select(getTab);
    const { tabKey, listTab, lastHomeUrl, subLastHomeUrl } = tabState;
    if (key === LOCALE_EN || key === LOCALE_VI) {
        return;
    }

    if (tabKey === key) {
        return;
    }

    yield put(setTabKey(key));

    if (key === 'home') {
        yield put(push(lastHomeUrl));
    } else if (key === 'subHome') {
        yield put(push(subLastHomeUrl));
    } else {
        const tab = find(listTab, { key });
        if (tab) {
            const state = yield select();
            const url = get(state, 'router.location.pathname');
            if (tabKey === 'home') {
                yield put(setLastHomeUrl(url));
            } else if (tabKey === 'subHome') {
                yield put(setSubLastHomeUrl(url));
            }
            yield put(push(tab.url));
        }
    }
}

function* handleReplaceTab(action) {
    const { key, newTabData, basePath } = action.payload;
    const listTab = yield select(getListTab);
    const tabKey = yield select(getTabKey);
    const newTabs = clone(listTab);
    const newTab = generateTab(newTabData, basePath);
    console.log('replaceTab', newTabs, newTab);
    // tìm xem tab mới đã có chưa
    let newTabIndex = findIndex(newTabs, { key: newTab.key });
    // newTab key chưa đc thêm thì tìm ví trí của key cần thay thế
    if (newTabIndex === -1) newTabIndex = findIndex(newTabs, { key });
    // tìm dc vị trí để replace tab
    if (newTabIndex > -1) {
        newTabs[newTabIndex] = newTab;
        yield put(setListTab(newTabs));
        if (tabKey === newTab.key) {
            yield put(replace(newTab.url));
        } else {
            // nếu dữ liệu tab replace là 1 tab khác đã được thêm thì active tab đó
            yield put(activeTabSagaAction({ key: newTab.key, tab: newTab }));
        }
    } else {
        // ko tìm thấy thì têm tab mới
        yield put(addTab(newTab));
        yield put(activeTabSagaAction({ key, tab: newTab }));
    }
}

function* handleCloseTab(action) {
    const { key } = action.payload;
    const tabKey = yield select(getTabKey);
    if (key === tabKey) {
        // set back to home tab when delete itself
        yield put(selectTab('home'));
    }
    yield put(closeTab(key));
}

function* handleReplaceCurrentTab(action) {
    const tabKey = yield select(getTabKey);
    const { newTabData, basePath } = action.payload;
    yield put(
        replaceTab({
            key: tabKey,
            newTabData,
            basePath
        })
    );
}

export default function* tabSaga() {
    yield all([
        yield takeEvery(ADD_TAB_SAGA, handleAddTab),
        yield takeEvery(ACTIVE_TAB_SAGA, handleActiveTab),
        yield takeEvery(SELECT_TAB_SAGA, handleSelectTab),
        yield takeEvery(REPLACE_TAB_SAGA, handleReplaceTab),
        yield takeEvery(CLOSE_TAB_SAGA, handleCloseTab),
        yield takeEvery(REPLACE_CURRENT_TAB, handleReplaceCurrentTab)
    ]);
}
