import { all } from 'redux-saga/effects';

import searchOne from './searchOne';
import tabChange from './tabChange';
import tabSaga from './tabSaga';

export default (dataProvider) => function* iTech() {
    yield all([
        tabChange(),
        searchOne(dataProvider)(),
        tabSaga()
    ]);
};
