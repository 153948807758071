export default {
    title: {
        procedureList: 'Service List',
        procedureCreate: 'Add service',
        procedureEdit: 'Edit service'
    },
    notification: {
        addTemplate: {
            success: 'Successfully updated content templates for the service',
            fail: 'Cannot update content templates for the service. Please try again or contact the administrator'
        },
        addPdfTemplate: {
            success: 'Successfully updated display template for the service',
            fail: 'Cannot update display templates for the service. Please try again or contact the administrator'
        },
        delete: {
            confirm: 'Do you sure to delete the procedure <b>%{name}</b> ?'
        }
    }
};
