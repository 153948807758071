export default {
  name: 'Ca chụp',
  fields: {
    accessionNumber: 'Mã ca',
    studyDate: 'Ngày',
    studyTime: 'Giờ chụp',
    bodyPartExamined: 'Bộ phận chụp',
    bodyPart: 'Bộ phận',
    modality: 'Tên thiết bị',
    modalityShort: 'Thiết bị',
    hospital: 'Bệnh viện',
    numOfImages: 'Ảnh',
    numOfSeries: 'Số series',
    status: 'Trạng thái',
    stage: 'Trạng thái',
    description: 'Mô tả',
    voidStatus: 'Trạng thái',
    images: 'Ảnh',
    imagesView: 'Xem ảnh',
    pid: 'Mã BN',
    pidShort: 'PID',
    patientName: 'Tên BN',
    patientId: 'Mã bệnh nhân',
    birthDate: 'Ngày sinh',
    age: 'Tuổi',
    gender: 'Giới tính',
    priority: 'Ưu tiên',
    orderPriority: 'Ưu tiên',
    assignee: 'Bác sĩ đọc',
    modalityName: 'Máy chụp',
    modalityId: 'Máy chụp',
    modalityNameShort: 'Tên TB',
    approval: 'Bác sĩ duyệt',
    assigneeName: 'Bác sĩ chẩn đoán',
    modalityType: 'Loại máy',
    studyModalityType: 'Loại máy chụp',
    modalityTypeShort: 'Loại',
    modalityAETitle: 'AE_Title',
    myAssign: 'Ca tôi đọc',
    myApprove: 'Ca tôi duyệt',
    reportStatus: 'Tình trạng kết quả',
    completedTime: 'Ngày chẩn đoán',
    includeMeta: 'Ẩn thông tin bệnh nhân',
    groupName: 'Nơi nhận',
    studyInstanceUid: 'Mã UID ca chụp',
    numOfFiles: 'Số tệp tin',
    requestDate: 'Ngày chỉ định',
    requestDepartment: 'Khoa yêu cầu',
    referringPhysician: 'BS chỉ định',
    note: 'Ghi chú',
    requestNumber: 'Mã yêu cầu',
    diagnosis: 'Kết luận',
    group: 'Đơn vị đọc ca',
    radiologist: 'Bác sĩ',
    numSeriesAndImages: 'Số S/I',
    order: 'Chỉ định',
    studyType: 'Loại ca',
    numOfInstances: 'Số Instances',
    anonymized: 'Ẩn danh',
    stt: 'STT',
    studyDescription: 'Mô tả',
    birthYear: 'Năm sinh',
    service: 'Dịch vụ',
    noConclusion: 'Chưa có kết quả chẩn đoán'
  },
  status: {
    draft: 'Bản nháp',
    unread: 'Chưa đọc',
    // readed: 'Đã đọc, chưa duyệt',
    readed: 'Chưa duyệt',
    approved: 'Đã duyệt',
    voided: 'Đã xóa'
  },
  statusFilter: {
    notStarted: 'Chưa đọc',
    inProgress: 'Đang đọc',
    pendingApproval: 'Đã đọc',
    approval: 'Đã duyệt',
    done: 'Đã in',
    cancelled: 'Đã hủy',
    voided: 'Đã xóa'
  },
  report: {
    all: 'Tất cả',
    unread: 'Chưa có',
    waiting: 'Chưa duyệt',
    done: 'Đã duyệt'
  },
  title: {
    popupFilter: 'Các trường tìm kiếm',
    default: 'Mặc định',
    info: 'Thông tin',
    history: 'Lịch sử chụp',
    readedHistory: 'Lịch sử đọc',
    reportHistory: 'Lịch sử kết quả đã đọc',
    similarStudies: 'Ca chụp cùng chỉ định',
    selectAttachment: 'Chọn ảnh',
    list: 'Danh sách',
    download: 'Tải về',
    tele: 'Hội chẩn từ xa',
    mapOrder: 'Ghép ca',
    detail: 'Chi tiết',
    template: 'Mẫu kết quả'
  },
  button: {
    editReport: 'Sửa kết quả',
    capturePhoto: 'Chụp ảnh',
    refresh: 'Làm mới',
    studyDetail: 'Mở ca',
    addProcedure: 'Thêm CĐ',
    selectTemplate: 'Mẫu KQ'
  },
  filter: {
    accessionNumber: 'Mã ca',
    patientName: 'Bệnh nhân',
    addFilter: 'Thêm trường',
    datePickingFilter: 'Chọn ngày từ',
    procedureName: 'Tên chỉ định',
    startDate: 'Từ ngày',
    endDate: 'Đến ngày',
    studyType: 'Loại chụp'
  },
  notify: { successChange: 'Đổi mật khẩu thành công', redirectLogin: 'Trở về màn hình đăng nhập sau 30 giây' }
};
