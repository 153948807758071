export default {
  title: {
    teleOrders: 'Dashboard Tele Orders',
    studies: 'Dashboard Studies',
    modalities: 'Dashboard Modalities',
    doctor: 'Dashboard Doctors'
  },
  label: {
    total: 'Total studies',
    statistic: 'Statistics from %{startDate} - %{endDate}',
    startDate: 'From',
    endDate: 'To',
    groupBy: 'Group by'
  },
  notification: {
    reportSummaryNotFound: 'Cannot find any report, please try again',
    startDateRequired: 'Start date is required',
    endDateRequired: 'End date is required'
  },
  weekDay: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday'
  }
};
