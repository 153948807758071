export default {
    name: 'Khoa phòng',
    fields: {
        type: 'Loại khoa',
        description: 'Mô tả',
        key: 'Mã khoa',
        name: 'Tên khoa'
    },
    title: {
        list: 'Danh sách',
        create: 'Thêm mới',
        edit: 'Thay đổi'
    },
    clinical: {
        title: 'Thuộc khoa chuyên môn',
        isClinical: 'Có',
        nonClinical: 'Không'
    }
};
