// import {required} from "react-admin";
import { memoize } from 'lodash';
import {
  msgPrefix,
  testAccessionNumber,
  testBirthDate,
  testEmail,
  testMid,
  testPasswordVerify,
  testPatientName,
  testPatternIP,
  testPatternPassword,
  testPatternPort,
  testPatternUsername,
  testPhoneNumber,
  testPid,
  testAge,
  testHospitalGroupId,
  testPdfTemplateId,
  testBirthYear
} from './inputValidate';

const msgPrefixRequired = `${msgPrefix}.required`;

const required = memoize((message = 'ra.validation.required') =>
  Object.assign(
    (value) =>
      value === null || value === undefined || (typeof value === 'string' && value === '') || (Array.isArray(value) && value.length === 0)
        ? message
        : undefined,
    { isRequired: true }
  )
);

export const validatePid = [required(`${msgPrefixRequired}.pid`), testPid];
export const validatePidUpload = [required(`${msgPrefixRequired}.pid`)];
export const validatePatientNameUpload = [required(`${msgPrefixRequired}.patientName`)];
export const validatePatientName = [required(`${msgPrefixRequired}.patientName`), testPatientName];
export const validateBirthDate = [required(`${msgPrefixRequired}.birthDate`), testBirthDate()];
export const validateGender = [required(`${msgPrefixRequired}.gender`)];
export const validateAge = [required(`${msgPrefixRequired}.age`)];

export const validateMid = [required(`${msgPrefixRequired}.mid`), testMid];
export const validateModalityName = [required(`${msgPrefixRequired}.modalityName`)];
export const validateModalityAe = [required(`${msgPrefixRequired}.modalityAe`)];
export const validateModalityIp = [required(`${msgPrefixRequired}.modalityIp`), testPatternIP];
export const validateModalityPort = [required(`${msgPrefixRequired}.modalityPort`), testPatternPort];
export const validateModalityGroupName = [required(`${msgPrefixRequired}.modalityGroupName`)];
export const validateListModalities = [required(`${msgPrefixRequired}.listModalities`)];

export const validateAccessionNumber = [required(`${msgPrefixRequired}.accessionNumber`), testAccessionNumber];
export const validateOrderDate = [required(`${msgPrefixRequired}.orderDate`)];
export const validateScheduleTime = [required(`${msgPrefixRequired}.scheduleTime`)];
export const validateDiagnosis = [required(`${msgPrefixRequired}.diagnosis`)];
export const validateDepartment = [required(`${msgPrefixRequired}.department`)];
export const validateDepartmentId = [required(`${msgPrefixRequired}.departmentId`)];
export const validateReferringPhysician = [required(`${msgPrefixRequired}.referringPhysician`)];
export const validateModalityType = [required(`${msgPrefixRequired}.modalityType`)];
export const validateModality = [required(`${msgPrefixRequired}.modality`)];
export const validateContrastMedia = [required(`${msgPrefixRequired}.contrastMedia`)];
export const validatePriority = [required(`${msgPrefixRequired}.priority`)];
export const validateStatus = [required(`${msgPrefixRequired}.status`)];
export const validateProtocol = [required(`${msgPrefixRequired}.protocol`)];
export const validateBodyPart = [required(`${msgPrefixRequired}.bodyPart`)];
export const validateUsername = [required(`${msgPrefixRequired}.username`), testPatternUsername];
export const validatePassword = [required(`${msgPrefixRequired}.password`), testPatternPassword];
export const validatePasswordVerify = [testPasswordVerify('password')];
export const validateNewPassword = [required(`${msgPrefixRequired}.newPassword`), testPatternPassword];
export const validateNewPasswordVerify = [testPasswordVerify('password')];
export const validateFullName = [required(`${msgPrefixRequired}.fullName`)];
export const validatePhoneNumber = [testPhoneNumber];
export const validateEmail = [testEmail];
export const validateTemplateName = [required(`${msgPrefixRequired}.templateName`)];
export const validateAuthority = [required(`${msgPrefixRequired}.authority`)];
export const validateAuthorityId = [required(`${msgPrefixRequired}.authorityId`)];
export const validateRoleId = [required(`${msgPrefixRequired}.roleId`)];
export const validateRole = [required(`${msgPrefixRequired}.role`)];
export const validateRoleName = [required(`${msgPrefixRequired}.roleName`)];
export const validateRoleAuthority = [required(`${msgPrefixRequired}.roleAuthority`)];
export const validateGroupId = [required(`${msgPrefixRequired}.groupId`)];
export const validateServiceId = [required(`${msgPrefixRequired}.serviceId`)];
export const validateServiceName = [required(`${msgPrefixRequired}.serviceName`)];
export const validateRegistrationNumber = [required(`${msgPrefixRequired}.registrationNumber`)];
export const validateBirthYear = [required(`${msgPrefixRequired}.birthYear`)];
export const validateAgeNotRequired = [testAge];
export const validateHospitalGroupName = [required(`${msgPrefixRequired}.hospitalGroupName`)];
export const validateHospitalGroupId = [required(`${msgPrefixRequired}.hospitalGroupId`), testHospitalGroupId];
export const validatePdfTemplateId = [required(`${msgPrefixRequired}.pdfTemplateId`), testPdfTemplateId];
export const validatePdfTemplateName = [required(`${msgPrefixRequired}.pdfTemplateName`)];
export const validateDescription = [required(`${msgPrefixRequired}.description`)];
export const validateBirthYearCanNull = [testBirthYear];
export * from './inputValidate';
