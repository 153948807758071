export default {
  title: {
    list: 'Danh sách thiết bị chụp',
    edit: 'Chỉnh sửa thiết bị chụp',
    create: 'Thêm thiết bị chụp',
    information: 'Thông tin thiết bị chụp',
    config: 'Cấu hình',
    rentHistory: 'Lịch sử cho thuê'
  },
  text: {
    selectRentType: 'Hãy lựa chọn loại máy',
    owner: 'Sở hữu',
    rent: 'Thuê',
    testConnect: 'Kiểm tra kết nối'
  },
  button: {
    testConnect: 'Kiểm tra'
  },
  notification: {
    duplicateId: 'Mã thiết bị đã tồn tại. Vui lòng chọn mã khác',
    testConnect: {
      success: 'Kết nối thành công',
      failure: 'Không thể kết nối với máy. Hãy kiểm tra lại cấu hình kết nối hoặc liên hệ quản trị viên!'
    },
    create: {
      success: 'Thêm thiết bị mới thành công',
      failure: 'Không thể thêm thiết bị mới. Vui lòng thử lại hoặc liên hệ quản trị viên.'
    },
    update: {
      success: 'Cập nhật thông tin thiết bị thành công',
      failure: 'Không thể chỉnh sửa thông tin thiết bị. Vui lòng thử lại hoặc liên hệ quản trị viên.'
    }
  }
};
