import { merge } from 'lodash';
import { haiPhongThemeDark, haiPhongThemeLight } from '../configurations/customThemes';

const initializeThemes = (customTheme, customParams) => {
    const themes = {
        light: { ...haiPhongThemeLight },
        dark: { ...haiPhongThemeDark }
    };
    const { documentTitle } = customParams || {};
    document.title = documentTitle || 'HIH Patient Portal';
    if (customTheme) {
        if (!customTheme.light || !customTheme.dark) {
            // dung chung cho ca light va dark
            merge(themes.light, customTheme);
            merge(themes.dark, customTheme);
        } else {
            merge(themes, customTheme);
        }
    }
    initializeFavicon(themes);
    // console.log('initializeThemes', themes);
    return themes;
};

const initializeFavicon = (themes) => {
    const favIcon = document.getElementById('favicon');
    if (favIcon) favIcon.href = '/images/logo-hai-phong-icon.png';
};

export default initializeThemes;
