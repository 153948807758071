export default {
  label: {
    username: 'Tên đăng nhập',
    password: 'Mật khẩu',
    remember: 'Duy trì đăng nhập'
  },
  button: {
    login: 'Đăng nhập',
    register: 'Đăng kí'
  },
  text: {
    formTitle: 'iPACS',
    formFooterIPACSCompact: 'Phiên bản phần mềm iPACS Compact\n',
    formFooter: 'Hỗ trợ kỹ thuật: 0962.800.362',
    forgetPassword: 'Quên mật khẩu',
    currentLogin: 'Tài khoản đăng nhập',
    resetPassword: 'Hãy liên hệ với quản trị viên để khôi phục lại mật khẩu!',
    falseLogin: 'Tên đăng nhập hoặc mật khẩu không đúng'
  },
  fields: {
    title: 'Tra cứu kết quả hình ảnh'
  }
};
