/* eslint-disable no-bitwise */
import React from 'react';
import { convertToHTML } from 'draft-convert';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import HtmlToRtf from 'html-to-rtf';
import { useTranslate } from 'react-admin';
import { memoize, pickBy, get } from 'lodash';
import { parse } from 'query-string';
import moment from 'moment';
import { createSelector } from 'reselect';
import { useTheme } from '@material-ui/core';

import { CHANGE_PASSWORDS, ROLES, TEMPLATES, USERS } from '../configurations/resources/resources';
import { inputValidate } from '../configurations/validation';
import { ipacsDark, ipacsLight, hospitalDark } from './imgString';
import { isValid } from 'date-fns';

// alert(isNotMobileOrTablet() ? 'is desktop' : 'is mobile');

export const createViewerLink = (study, reportResource, studyResource, studyIdField = 'id', studyUuidField = 'uuid') =>
  `/viewer/local/${study[studyIdField]}/${study[studyIdField]}.${study[studyUuidField]}`;

export const createMultiStudiesViewerLink = (studies, studyResource) => {
  const combineUUID = studies.map((study) => `${study.id}.${study.uuid}`).join(',');
  return `/viewer/local/${studies[0].id}/${combineUUID}`;
};
// const params = {
//     // u1: process.env.REACT_APP_STORAGE_RS,
//     // u2: process.env.REACT_APP_STORAGE_URI,
//     // t1: 'wadors',
//     s1: `${study.id}.${study.uuid}`,
//     studyId: studyResource === TELE_STUDIES ? study.studyId : study.id,
//     reportResource
// };
// // return `${process.env.REACT_APP_VIEWER}?${stringify(params)}`;
// return `/viewer?${stringify(params)}`;
// ;

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const dateShowFormat = 'dd-MM-yyyy';
export const dateStoreFormat = 'YYYY-MM-DDTHH:mm:ssZZ';
// YYYY-MM-DD HH:mm:ss ZZ

export const isEmpty = (obj) => obj === undefined || obj === null || Object.keys(obj).length === 0;

export const cleanObject = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') cleanObject(val);
    else if (val == null || val === undefined) delete obj[key];
    else if (typeof val === 'string') obj[key] = val.trim().replace(/[\s]+/g, ' ');
  });
  return obj;
};

export const notificationName = (recordForm, resource, translate) => {
  const { values } = recordForm;
  switch (resource) {
    case USERS:
      return `${translate(`resources.${resource}.name`)} ${values.username ? `<b>${values.username}</b>` : ''}`;
    case ROLES:
      return `${translate(`resources.${resource}.name`)} ${values.id ? `<b>${values.id}</b>` : ''}`;
    case TEMPLATES:
      return `${translate(`resources.${resource}.name`)} ${values.templateName ? `<b>${values.templateName}</b>` : ''}`;
    case CHANGE_PASSWORDS:
      return `${translate('resources.password.name')}`;
    default:
      return `${translate(`resources.${resource}.name`)}`;
  }
};

/**
 *  @param syncErrors - {object} : form invalid messages
 *  @param translate - {function} : translate function need to translate invalid message
 */
export const getInvalidMessages = (syncErrors, translate) => {
  let result = [];
  Object.values(syncErrors).forEach((value) => {
    if (typeof value === 'string') {
      result.push(translate ? translate(value) : value);
    } else if (typeof value === 'object') {
      result = [...result, ...getInvalidMessages(value, translate)];
    }
  });
  return result;
};

export const createEditorStateWithText = (text) => EditorState.createWithContent(ContentState.createFromText(text));

// check empty username,password,validate password
export const checkFormValidate = (form, translate) => {
  const names = Object.keys(form);
  const invalid = {};
  console.log(form);
  try {
    for (const name of names) {
      const value = form[name];
      if (typeof value === 'string') {
        if (value.length === 0) {
          invalid[name] = `validation.invalid.${name}`;
        }
        if (name === 'password') {
          if (value.length < 6) {
            invalid['invalidPassword'] = 'validation.invalid.invalidPassword';
          }
        }
      }
    }
    console.log(invalid.username);
  } catch (error) {
    console.err(error);
  }
  if (!isEmpty(invalid)) {
    let message = Object.values(invalid).map((msg) => translate(msg));
    if (invalid.username === undefined) {
      message = ['', ...message];
    }
    return message;
  }
  return !isEmpty(isValid);
};

export const getStudyDownloadFailed = (studyLink) => studyLink.filter((link) => link.stage === 'FAILED');

export const getDraftJsEditor = (raw) => {
  let editor;
  if (raw && typeof raw === 'string') {
    try {
      editor = EditorState.createWithContent(convertFromRaw(JSON.parse(raw)));
    } catch (e) {
      // console.error(e);
      editor = createEditorStateWithText(raw);
    }
  } else editor = createEditorStateWithText('');
  return editor;
};

function strEncodeUTF16(str) {
  const converted = [];
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    const charCode = str.charCodeAt(i);
    if (charCode < 128) {
      converted.push(str[i]);
    } else {
      converted.push(`\\u${charCode}?`);
    }
  }
  return converted.join('');
}

export const getRtfContent = (editorState) => {
  try {
    const html = convertToHTML(editorState.getCurrentContent());
    const rtfContent = strEncodeUTF16(HtmlToRtf.convertHtmlToRtf(html));

    return rtfContent;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getUrlParams = memoize((search) => {
  if (!search) return '';
  const result = parse(search);
  if (result.filter) result.filter = JSON.parse(result.filter);
  return result;
});

export const getAge = memoize((value) => {
  const birthDate = moment(value);
  return birthDate.isValid() ? moment().diff(birthDate, 'years') : '';
});

export const preventDefaultOnClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const arrayFontSize = (start, limit) => {
  const fontSizes = [];
  for (let index = start; index <= limit; index++) {
    fontSizes.push({ id: index, name: index });
  }
  return fontSizes;
};

export const hasCustomParams = (params) =>
  params &&
  ((params.filter && Object.keys(params.filter).length > 0) ||
    params.order != null ||
    params.page !== 1 ||
    params.perPage != null ||
    params.sort != null);

const validQueryParams = ['page', 'perPage', 'sort', 'order', 'filter'];
const getLocationPath = (props) => props.location.pathname;
const getLocationSearch = (props) => props.location.search;
export const selectQuery = createSelector(getLocationPath, getLocationSearch, (path, search) => {
  const query = pickBy(parse(search), (v, k) => validQueryParams.indexOf(k) !== -1);
  if (query.filter && typeof query.filter === 'string') {
    try {
      query.filter = JSON.parse(query.filter);
    } catch (err) {
      delete query.filter;
    }
  }
  return query;
});

export const StyledTitle = (props) => {
  const { resource, type, meta = {} } = props;
  const translate = useTranslate();
  const theme = useTheme();
  const { showPatientName, isTabGroup, title, subTitle } = meta;
  const patientName = get(meta, ['data', 'order', 'patient', 'fullName'], '');
  const age = get(meta, ['data', 'patient', 'age']);
  const gender = get(meta, ['data', 'patient', 'gender']);
  const titleMain = isTabGroup ? (
    <label className="my-0">
      <span style={{ color: theme.palette.primary.styledTitle }}>
        {translate(title)}
        {' | '}
      </span>
      {translate(subTitle)}
    </label>
  ) : (
    <label className="my-0">
      {/* <span style={{ color: theme.palette.primary.styledTitle }}> */}
      <span style={{ color: 'var(--main-color-dark)' }}>
        {translate(`resources.${resource}.name`).toUpperCase()}
        {' | '}
      </span>
      {showPatientName
        ? `${patientName}${age ? ` - ${age}` : ''}${gender ? ` - ${gender}` : ''}`
        : translate(`resources.${resource}.title.${type}`)}
    </label>
  );
  console.log('titleMain', props);
  return titleMain;
};

export const getResourceDataById = (resource, id) => (state) => get(state, ['admin', 'resources', resource, 'data', id], {});

export const setTopHeight = (height) => {
  const DEFAULT_TOP_HEIGHT = '100px';
  const root = document.documentElement;
  root.style.setProperty('--top-height', height || DEFAULT_TOP_HEIGHT);
};

export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

export const delayPromise = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const dataURItoBlobv = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });
  return blob;
};

export const headerRequest = () => ({
  'Corporate-Id': process.env.REACT_APP_NAME,
  'Request-Id': uuidv4(),
  'Request-Time': moment().format(dateStoreFormat)
});

export const searchData = (data, value) => {
  const newData = data.filter((item) => item.name.toLowerCase().indexOf(value.trim().toLowerCase()) > -1);
  return newData;
};

export const getThumbOfVideo = (event) => {
  const file = event.target.files[0];
  const fileReader = new FileReader();
  fileReader.onload = () => {
    const blob = new Blob([fileReader.result], { type: file.type });
    const url = URL.createObjectURL(blob);
    const video = document.createElement('video');
    const timeupdate = () => {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
        video.pause();
      }
    };
    video.addEventListener('loadeddata', () => {
      if (snapImage()) {
        video.removeEventListener('timeupdate', timeupdate);
      }
    });
    const snapImage = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL();
      const success = image.length > 100000;
      if (success) {
        const img = document.createElement('img');
        img.src = image;
        document.getElementsByTagName('div')[0].appendChild(img);
        URL.revokeObjectURL(url);
      }
      return success;
    };
    video.addEventListener('timeupdate', timeupdate);
    video.preload = 'metadata';
    video.src = url;
    // Load video in Safari / IE11
    video.muted = true;
    video.playsInline = true;
    video.play();
  };
  fileReader.readAsArrayBuffer(file);
};

/**
 * get image string
 * @param {*} theme - light or dark
 * @param {*} type - itech or hospital
 */
export const getImgString = (theme = 'light', type = 'ipacs') => {
  console.log('img string', theme, type);
  // eslint-disable-next-line no-nested-ternary
  return type === 'ipacs' ? (theme === 'dark' ? ipacsDark : ipacsLight) : theme === 'dark' ? hospitalDark : ipacsLight;
};
