export const store = {
    name: 'PACS',
    title: {
        statistic: 'Capacity statistic',
        manage: 'Manage study',
        list: 'DICOM databases list'
    },
    fields: {
        status: 'Status',
        type: 'Type',
        aeTitle: 'AETitle',
        host: 'Host',
        port: 'Port',
        used: 'Used(MB)',
        max: 'Max(MB)',
        numStudy: 'Studies',
        totalStudy: 'Total Studies: ',
        totalSizeUsed: 'Used size (GB): ',
        maxSize: 'Max size (GB): ',
        SIUID: 'SIUID'
    },
    notification: {
        delete: 'Do you really want to delete this study?'
    }
};

export default store;
