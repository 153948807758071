export default {
    notification: {
        create: {
            successful: 'Create new folder is successful',
            failure: 'Can\'t create new folder. Please try again or contact admin'
        },
        edit: {
            successful: 'Update folder name is successful',
            failure: 'Can\'t update folder %{labelName}. Please try again or contact admin'
        },
        createSubLabel: {
            successful: 'Create subfolder of folder %{labelName} is successful',
            failure: 'Can\'t create new subfolder for folder %{labelName}. Please try again or contact admin'
        },
        remove: {
            confirm: 'Do you want to remove folder %{labelName}?',
            successful: 'Remove folder %{labelName} is successful',
            failure: 'Can\'t remove folder %{labelName}. Please try again or contact admin'
        }
    }
};
