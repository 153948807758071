export const users = {
    name: 'User',
    title: {
        list: 'List',
        create: 'Create',
        edit: 'Edit',
        detail: 'Detail',
        changePassword: 'Change password'
    },
    fields: {
        uuid: 'System ID',
        username: 'Username',
        password: 'Password',
        firstName: 'First name',
        middleName: 'Middle name',
        lastName: 'Last name',
        address: 'Address',
        district: 'District',
        city: 'City',
        country: 'Country',
        nationality: 'Nationality',
        birthDate: 'Date of birth',
        gender: 'Gender',
        roles: 'Roles',
        authorities: 'Authorities',
        fullName: 'Full name',
        degree: 'Degree',
        race: 'Race',
        phone: 'Phone number',
        email: 'Email',
        passwordVerify: 'Confirm password',
        currentPassword: 'Current password',
        newPassword: 'New password',
        newPasswordVerify: 'Verify new password',
        department: 'Department',
        specialization: 'Specialization',
        roleName: 'Login authority',
        accessableModalityGroup: 'Accessable modality group',
        roleId: 'Role ID',
        privileges: 'Privileges',
        title: 'Title',
        enabled: {
            title: 'Enable',
            active: 'Yes',
            inactive: 'No'
        }
    },
    button: {
        findStaff: 'Select an existing staff',
        updateUserInfo: 'Update information',
        updateGroup: 'Update modality group',
        addModalityGroup: 'Add modality group'
    },
    notification: {
        duplicateUsername: 'Username has already existed, please try again.',
        changePassword: {
            confirm: 'Do you sure to change this password?',
            currentPassword: 'Current password is invalid, please try again.',
            success: 'Change password is successfully',
            failure: 'Change password is failure'
        }
    }
};

export default users;
