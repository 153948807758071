import { lazy } from 'react';
import {
  STUDY
} from '../configurations/resources/resources';

export const URL_CHANGE_PASS = '/change-password';
export const URL_STUDY_LIST = `/${STUDY}-list`;
export const URL_STUDY_LIST_ALL = `${URL_STUDY_LIST}/all`;
export const PATIENT = '/patient';

// lazy load components
export const LazyLogin = lazy(() => import('./login/Login'));

export const LazyMain = lazy(() => import('./main/Main'));
export const LazyAuthenticator = lazy(() => import('./main/Authenticator'));

export const LazyStudies = lazy(() => import('./history/StudiesHistory'));
export const LazyStudiesMobile = lazy(() => import('./history/mobile/StudiesHistoryMobile'));

export const LazyProfile = lazy(() => import('./profile/Profile'));

export const LazyPasswordChange = lazy(() => import('./history/mobile/ChangePasswordMobile'));
