export default {
  invalid: {
    required: {
      pid: 'Mã bệnh nhân là bắt buộc',
      patientName: 'Tên bệnh nhân là bắt buộc',
      birthDate: 'Ngày sinh là bắt buộc và theo định dạng Ngày-Tháng-Năm',
      gender: 'Giới tính là bắt buộc',
      age: 'Tuổi là bắt buộc',
      accessionNumber: 'Mã chỉ định chụp là bắt buộc',

      mid: 'Mã thiết bị là bắt buộc',
      modality: 'Máy chụp là bắt buộc',
      modalityName: 'Tên thiết bị là bắt buộc',
      modalityType: 'Loại thiết bị là bắt buộc',
      modalityAe: 'AE Title là bắt buộc',
      modalityIp: 'Địa chỉ IP là bắt buộc',
      modalityPort: 'Cổng là bắt buộc',
      modalityGroupName: 'Tên nhóm là bắt buộc',
      listModalities: 'Danh sách máy là bắt buộc',

      orderId: 'Mã chỉ định chụp là bắt buộc',
      orderDate: 'Ngày chỉ định chụp là bắt buộc',
      scheduleTime: 'Ngày chỉ định chụp là bắt buộc',
      diagnosis: 'Chẩn đoán lâm sàng là bắt buộc',
      department: 'Khoa yêu cầu là bắt buộc',
      departmentId: 'Mã khoa yêu cầu là bắt buộc',
      referringPhysician: 'Bác sĩ điều trị là bắt buộc',
      contrastMedia: 'Thông tin thuốc cản quang là bắt buộc',
      priority: 'Độ ưu tiên là bắt buộc',
      status: 'Trạng thái là bắt buộc',
      protocol: 'Kỹ thuật chụp là bắt buộc',

      bodyPart: 'Bộ phận chụp là bắt buộc',

      currentPassword: 'Hãy nhập mật khẩu',
      username: 'Hãy nhập Tên đăng nhập',
      password: 'Hãy nhập mật khẩu',
      newPasswordVerify: 'Mật khẩu không khớp',
      invalidPassword: 'Nhập 6 kí tự trở lên cho mật khẩu',
      passwordVerify: 'Hãy nhập mật khẩu',
      falseLogin: 'Tên đăng nhập hoặc mật khẩu không đúng',
      newPassword: 'Mật khẩu mới là bắt buộc',
      fullName: 'Tên đầy đủ là bắt buộc',
      phone: 'Số điện thoại là bắt buộc',
      email: 'Email là bắt buộc',
      degree: 'Học vị là bắt buộc',

      templateName: 'Tên mẫu nội dung KQ là bắt buộc',
      authority: 'Tên quyền truy cập là bắt buộc',
      authorityId: 'Mã quyền truy cập là bắt buộc',
      roleId: 'Mã là bắt buộc',
      role: 'Chức vụ là bắt buộc',
      roleName: 'Tên chức vụ là bắt buộc',
      roleAuthority: 'Quyền truy cập không được để trống',

      groupId: 'Nơi gửi ca hội chẩn là bắt buộc',

      serviceId: 'Mã dịch vụ là bắt buộc',
      serviceName: 'Tên dịch vụ là bắt buộc',
      registrationNumber: 'Số thứ tự là bắt buộc',
      birthYear: 'Năm sinh là bắt buộc',
      hospitalGroupName: 'Tên nhóm bác sĩ là bắt buộc',
      hospitalGroupId: 'Mã nhóm bác sĩ là bắt buộc',
      description: 'Mô tả là bắt buộc',
      pdfTemplateId: 'Mã mẫu là bắt buộc',
      pdfTemplateName: 'Tên mẫu là bắt buộc'
    },
    ip: 'Địa chỉ IP không hợp lệ. Xin vui lòng nhập lại',
    port: 'Cổng không hợp lệ. Xin vui lòng nhập lại',
    pid: 'Mã bệnh nhân có ít nhất 3 kí tự, bao gồm chữ cái, chữ số và kí tự đặc biệt',
    mid: 'Mã thiết bị có ít nhất 3 kí tự, chỉ bao gồm chữ và số',

    orderId: 'Mã chỉ định chụp có ít nhất 3 kí tự, chỉ bao gồm chữ cái, không chứa kí tự đặc biệt',
    patientName: 'Tên bệnh nhân có ít nhất 3 kí tự, chỉ bao gồm chữ cái, không chứa kí tự đặc biệt',
    pn: 'Tên bệnh nhân có ít nhất 3 kí tự, chỉ bao gồm chữ cái, không chứa kí tự đặc biệt',
    accessionNumber: 'Mã chỉ định chụp có ít nhất 3 kí tự, bao gồm chữ cái, chữ số và không chứa kí tự đặc biệt',
    nationalId: 'Số CMND/CCCD có từ 9 đến 12 kí tự, chỉ bao gồm số, không chứa kí tự đặc biệt',
    phone: 'Số điện thoại có từ 10-11 kí tự, chỉ bao gồm số, không chứa kí tự đặc biệt',
    brand: 'Nhẫn hiệu có ít nhất 3 kí tự, chỉ bao gồm chữ và số',
    birthDate: 'Năm sinh không được nhỏ hơn 1900',

    username: 'Hãy nhập Tên đăng nhập',
    password: 'Hãy nhập mật khẩu',
    invalidPassword: 'Nhập 6 kí tự trở lên cho mật khẩu',
    email: 'Email không đúng định dạng',
    birthYear: 'Năm sinh bị sai định dạng. Xin thử lại',
    age: 'Tuổi phải nằm trong khoảng 0 đến 200',
    hospitalGroupId: 'Mã nhóm có ít nhất 3 kí tự, chỉ bao gồm chữ cái, số, không chưa kí tự đặc biệt',
    pdfTemplateId: 'Mã mẫu hiển thị KQ có ít nhất 3 kí tự, chỉ bao gồm chữ cái, số, không chưa kí tự đặc biệt'
  }
};
