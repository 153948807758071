import { GET_APP_CONFIG_SUCCESS } from '../actions';

const initialState = { standalone: true };

export default (previousState = initialState, action) => {
    const { type, payload } = action;
    if (type === GET_APP_CONFIG_SUCCESS) {
        return { ...previousState, ...payload.data };
    } return previousState;
};
