export default {
    title: {
        list: 'List of studies',
        create: 'Create studies'
    },
    notification: {
        delete: 'Ban có chắc chắn muốn xóa ca chụp của bệnh nhân <b>%{patientName}</b> không?',
        create: {
            success: 'The uploaded study is being processed',
            fail: 'Cannot upload study because network connection. Please try again or contact Administrators!'
        },
        confirm: {
            before: 'Do you sure want to create new study',
            success: 'Confirm successfully'
        }
    },
    label: {
        numberOfFiles: 'Number of files: %{numberOfFiles}',
        fileName: 'File name',
        fileSize: 'File size',
        fileType: 'Định dạng',
        preview: 'Preview',
        deleteFile: 'Delete file',
        page_rows_per_page: 'Files per page:',
        no_results: 'No selected files',
        chooseFolder: 'Choose folder',
        chooseFiles: 'Choose files',
        onlyDICOM: 'Support DICOM images only',
        chooseStudy: 'Choose a study to change information',
        create: 'Upload new study',
        studyInfo: 'Study info',
        inprogress: 'Inprogress'
    }
};
