import { CLEAR_STATE } from 'react-admin';
import { SET_MODALITY_INFO } from '../actions';

const initialState = { };

export default (previousState = initialState, action) => {
    const { type, payload } = action;
    if (type === SET_MODALITY_INFO) {
        return { ...previousState, ...payload };
    }
    if (type === CLEAR_STATE) {
        return initialState;
    }
    return previousState;
};
