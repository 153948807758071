export const patients = {
    name: 'Bệnh nhân',
    fields: {
        id: 'Mã bệnh nhân',
        pid: 'Mã bệnh nhân',
        pidShortHand: 'Mã BN',
        gender: 'Giới tính',
        sex: 'Giới tính',
        fullName: 'Tên bệnh nhân',
        patientName: 'Tên bệnh nhân',
        name: 'Họ tên',
        patientAddresses: 'Địa chỉ',
        patientIdentifiers: 'Giấy tờ tùy thân',
        patientPhones: 'Số ĐT',
        patientEmails: 'Emails',
        patientNationalIds: 'Số CMND/CCCD',
        patientPassports: 'Hộ chiếu',
        birthDate: 'Ngày sinh',
        age: 'Tuổi',
        estimated: '(Ước chừng)',
        preferred: 'Ưu tiên',
        address1: 'Số nhà, đường',
        address2: 'Tầng, phòng...',
        address3: 'Thông tin thêm',
        city: 'Tỉnh/Thành phố',
        district: 'Quận/Huyện',
        country: 'Quốc gia',
        postalCode: 'Mã bưu điện',
        verified: 'Đã xác thực',
        phone: 'Điện thoại',
        countryCode: 'Mã quốc gia',
        email: 'Email',
        address: 'Địa chỉ',
        identifierType: 'Loại giấy tờ',
        identifier: 'Giấy tờ tùy thân',
        addressComp: 'Nơi làm việc',
        foreigner: 'Người nước ngoài',
        identificationCard: 'Số CMTND',
        assuranceNumber: 'Số BHYT',
        nation: 'Dân tộc',
        job: 'Nghề nghiệp',
        allergyNotes: 'Dị ứng/Chống chỉ định',
        birthYear: 'Năm sinh'
    },
    entity: {
        title: 'Đối tượng',
        assurance: 'Bảo hiểm y tế',
        fee: 'Thu phí',
        free: 'Miễn phí',
        other: 'Khác'
    }
};

export default patients;
