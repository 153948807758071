import { TELE_AUTHORIZATION, TELE_AUTHORIZATION_SUCCESS } from '../actions/teleAuthorization';

const initialState = {};

export default (previousState = initialState, action) => {
    const { type, payload } = action;
    if (type === TELE_AUTHORIZATION) {
        const { callback } = payload;
        return { ...previousState, authorizationCallback: callback };
    } if (type === TELE_AUTHORIZATION_SUCCESS) {
        // console.log('tele authorization success', previousState, action);
        const { authorizationCode } = payload;
        return authorizationCode ? { ...previousState, authorizationCode } : previousState;
    }
    return previousState;
};
