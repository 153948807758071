import { createMuiTheme } from '@material-ui/core';

export const LIST_COLOR = {
  primaryColor: '#2957A4'
};

export const haiPhongThemeLight = createMuiTheme({
  palette: {
    primary: {
      mainColor: '#42B3E5',
      main: '#42B3E5', // palette mus have 'main' atribute
      mainColorLight: '#42B3E5',
      mainColorDark: LIST_COLOR.primaryColor,
      mainColorHover: '#0384CE',
      buttonColorPrimary: '#FFFFFF',
      buttonColorSecondary: '#E3ECF3',
      contentBackground: '#f7f9fa',
      mainBackground: 'rgba(0, 0, 0, 0.05)',
      sidebarBackground: '#eff0f3',
      sidebarHeaderBackground: '#eff0f3',
      formBackgroundColor: '#E3ECF3',
      formBackgroundColorFocus: '#cccccc',
      formBackgroundColorDisabled: '#eff0f3',
      defaultText: '#333547',
      textColor: '#202124',
      textHighlightColor: '#f7f9fa',
      textColorAlternate: '#202124',
      textColorLink: '#626AAB',
      textColorDone: '#0384CE',
      textColorUnread: '#C64C54',
      tableOdd: '#eff0f3',
      tableEven: '#f7f9fa',
      tableActiveRow: '#2957A4',
      modalItechBackground: '#FFFFFF',
      reportBackgroundColor: '#eff0f3',
      shadow: 'rgb(0, 0, 0, 0)',
      borderColor: '#333547',
      tabHeight: '30px',
      greyBackground: 'rgb(201, 201, 201)',
      sidebarHeaderColor: '#0384CE',

      primaryColor: LIST_COLOR.primaryColor,

      activeColor: LIST_COLOR.primaryColor
    },
    type: 'light'
  },
  logo: {
    navbar: {
      type: 'svg',
      src: '/images/logo-hai-phong.svg'
    }
  }
});

export const haiPhongThemeDark = createMuiTheme({
  palette: {
    primary: {
      mainColor: '#42B3E5',
      main: '#42B3E5', // palette mus have 'main' atribute
      mainColorLight: '#42B3E5',
      mainColorDark: '#0384CE',
      mainColorHover: '#0384CE',
      buttonColorPrimary: '#0384CE',
      buttonColorSecondary: '#42B3E5',
      contentBackground: '#202124',
      // mainBackground: 'rgba(0, 0, 0, 0.05)',
      mainBackground: '#E3ECF3',
      sidebarBackground: '#2a2c2f',
      sidebarHeaderBackground: '#2a2c2f',
      formBackgroundColor: '#ffffff',
      formBackgroundColorFocus: '#cccccc',
      formBackgroundColorDisabled: '#202124',
      defaultText: 'rgba(255, 255, 255, 0.7)',
      textColor: 'rgba(255, 255, 255, 0.7)',
      textColorAlternate: 'rgba(255, 255, 255, 0.7)',
      textColorLight: '#495057',
      textColorLink: '#626AAB',
      textColorDone: '#0384CE',
      textColorUnread: '#C64C54',
      textHighlightColor: '#248037',
      tableOdd: '#2a2c2f',
      tableEven: '#202124',
      modalItechBackground: '#2a2c2f',
      reportBackgroundColor: '#2a2c2f',
      shadow: 'rgb(0, 0, 0, 0)',
      borderColor: '#2a2c2f',
      tabHeight: '30px',
      greyBackground: 'rgb(201, 201, 201)',
      sidebarHeaderColor: 'rgba(255, 255, 255, 0.7)'
    },
    type: 'dark'
  },
  logo: {
    navbar: {
      type: 'svg',
      src: '/images/logo-hai-phong-dark.svg'
    }
  }
});
